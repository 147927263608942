import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { registrationApi } from "../AllAPIS";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { ErrorToaster } from "../Toasters";
import { connect } from "react-redux";
import "./../Styles/signup.css";
import { t } from "i18next";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

function SignUp({ getStoreUserToken }) {
  const navigation = useNavigate();
  const [validated, setValidated] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [emailError, setEmailrror] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [emailMsg, setEmailMsg] = useState("");
  const [passwordMsg, setPasswordMsg] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const [showAppModal,  setShowAppModal] = useState(false)

  const handleSignup = (e) => {
    e.preventDefault();
    if (
      email === "" &&
      password === "" &&
      userName === "" &&
      cpassword === ""
    ) {
      ErrorToaster("Please Fill The All Fields");
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
    } else if (email === "") {
      setEmailrror(true);
      ErrorToaster("Email Is Required");
      setEmailMsg("Email Is Required");
    } else if (userName === "") {
      setUserNameError(true);
      ErrorToaster("User Name Is Required");
    } else if (emailreg.test(email) === false) {
      ErrorToaster("Email Format is not correct it Contain @ & .com");
      setEmailMsg("Email Format is not correct it Contain @ & .com");
      setEmailrror(true);
    } else if (password === "") {
      setPasswordError(true);
      ErrorToaster("Passwrod Is Required");
      setPasswordMsg("Passwrod Is Required");
    } else if (password !== cpassword) {
      setPasswordError(true);
      ErrorToaster("Confirm Password and Password Does Not Match");
      setPasswordMsg("Confirm Password and Password Does Not Match");
    } else if (passReg.test(password) === false) {
      ErrorToaster(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
      setPasswordError(true);
      setPasswordMsg(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else {
      let registrationData = {
        name: userName,
        email: email,
        password: password,
        role: "4",
        login_device_type: "web",
      };
      registrationApi(registrationData, navigation, setShowAppModal);
    }
  };

  useEffect(() => {
    if (getStoreUserToken) {
      ErrorToaster("You Have Already Signup");
      navigation("/");
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="side-image-signup">
              <img src="/img/signup-image.png" alt="" />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="col-md-8 ">
              <div className="signup-form">
                <h2 className="pixel-26 fw-bold ">{t("signup1")}</h2>
                <h6 className="text-muted pixel-16 mb-4">
                  {t("signup2")}
                </h6>
                <div className="signup-inputs">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSignup}
                  >
                    <Form.Group
                      className="mb-3"
                      md="4"
                      controlId="validationCustomUsername"
                    >
                      <Form.Label
                        className={userNameError ? "signup-error" : ""}
                      >
                        {t("signup3")}*
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={t("signup4")}
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                          setUserNameError(false);
                        }}
                        className={userNameError ? "signup-error" : ""}
                      />

                      {userNameError ? (
                        <span className="signup-error">
                         {t("signup5")}
                        </span>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          {t("signup6")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="validationCustomEmail"
                    >
                      <Form.Label className={emailError ? "signup-error" : ""}>
                        {t("signup7")}*
                      </Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder={t("signup8")}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailrror(false);
                          setEmailMsg("");
                        }}
                      />
                      {emailError ? (
                        <span className="signup-error">{emailMsg}</span>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          {t("signup9")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="validationCustumPassword"
                    >
                      <Form.Label
                        className={passwordError ? "signup-error" : ""}
                      >
                        {t("Password")}*
                      </Form.Label>

                      <div className="d-flex">
                        <Form.Control
                          required
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder={t("Password")}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(false);
                          }}
                        />
                        {showPassword ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setShowPassword(!showPassword)}
                            className="eye-icond"
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setShowPassword(!showPassword)}
                            className="eye-icond"
                          />
                        )}
                      </div>
                      {passwordError ? (
                        <span className="signup-error">{passwordMsg}</span>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          {t("signup10")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicCPassword">
                      <Form.Label
                        className={passwordError ? "signup-error" : ""}
                      >
                         {t("signup11")}*
                      </Form.Label>
                      <div className="d-flex">
                        <Form.Control
                          required
                          type={showCPassword ? "text" : "password"}
                          placeholder={t("signup12")}
                          value={cpassword}
                          onChange={(e) => setCPassword(e.target.value)}
                        />
                        {showCPassword ? (
                          <AiOutlineEyeInvisible
                            onClick={() => setShowCPassword(!showCPassword)}
                            className="eye-icon"
                          />
                        ) : (
                          <AiOutlineEye
                            onClick={() => setShowCPassword(!showCPassword)}
                            className="eye-icon"
                          />
                        )}
                      </div>

                      {passwordError ? (
                        <span className="signup-error">{passwordMsg}</span>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          {t("signup13")}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <button className="btn-colored mt-3" type="submit">
                      {t("signup15")}
                    </button>
                  </Form>
                  <div className="text-mute pixel-14 mt-4 already-account">
                    {t("signup14")}
                    <span
                      onClick={() => {
                        navigation("/login");
                      }}
                    >
                      {t("signup16")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

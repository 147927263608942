import React from "react";
import "./../Styles/offers.css";
import { useTranslation } from "react-i18next";


function Offers() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container my-4 py-3">
        <div className="column">
          <div className="offer-heading">
            <h1 className="text-center">
              <span> {t("What We Offer")}</span>
            </h1>
            <h4 className="text-center">
             {t("workflow_para")}
            </h4>
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-md-3">
                <div className={`offer-card`}>
                  <img src="/img/offer_images/1.png" alt="image" />
                  <h2>End-to-End Café Consultancy and Training</h2>
                  <p>
                    We offer extensive barista trainings and espresso traini ngs
                    for new café owners, café staff, coffee aficionados,
                    baristas and anyone who wants to learn the art of maki ng a
                    good cup of coffee.
                  </p>
                  <button>READ MORE</button>
                </div>
              </div>

              <div className="col-md-3">
                <div className={`offer-card`}>
                  <img src="/img/offer_images/2.png" alt="image" />
                  <h2>Widest Range of Coffee Equipment</h2>
                  <p>
                    Our machines are designed to take coffee indulgence the next
                    level. Whether it is regular coffee, cappuccino, espresso,
                    milk coffee or customised creations, we have the equipment
                    which reveals coffee in its glory.
                  </p>
                  <button>READ MORE</button>
                </div>
              </div>

              <div className="col-md-3">
                <div className={`offer-card`}>
                  <img src="/img/offer_images/3.png" alt="image" />
                  <h2>Technology</h2>
                  <p>
                    Technology is an integral part of Kaapi Machines and we
                    always look forward to innovate and improvise. We always
                    look forward to try and bring new technologies to which can
                    help our customers reach new heights.
                  </p>
                  <button>READ MORE</button>
                </div>
              </div>

              <div className="col-md-3">
                <div className={`offer-card`}>
                  <img src="/img/offer_images/4.png" alt="image" />
                  <h2>World Class Service And Support</h2>
                  <p>
                    Importantly, we back our products with world-class service
                    and maintenance that you can rely on anytime, anywhere.
                  </p>
                  <button>READ MORE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Offers;

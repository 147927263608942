import React from 'react'
import Home from './Component/Home';
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import ServicesPage from './Pages/ServicesPage';
import BookingPage from './Pages/BookingPage';
import ViewCart from './Component/ViewCart';
import TechnicianRegistration from './Pages/TechnicianRegistration';
import TechnicianHome from './Component/TechnicianHome';
import ServicesComponent from './Component/ServicesComponent';
import Contactus from './Pages/Contactus';
import Privacy from './Pages/Privacy';
import Aboutus from './Pages/Aboutus';
import Term from './Pages/Term';
import Return from './Pages/Return';
import Error from './Pages/Error';
import PaypalMobile from './Component/PaypalMobile';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
    {
      path: "/",
      element: ( <Home/>),
    },
    {
      path: "signup",
      element:  <SignUp/>,
    },
    {
      path: "login",
      element: <Login/>,
    },
    {
      path:'coffee-machine-a-cafe/booking/details',
      element:<BookingPage/>
    },
    {
      path:'/service/:slug/:slug',
      element:<ServicesPage/>
    },
    {
      path:'coffee-machine-a-cafe/cart-view',
      element:<ViewCart />
    },
    {
      path:'signup-technician',
      element:<TechnicianRegistration />
    },
    {
      path:'home-technician',
      element:<TechnicianHome />
    },{
      path:'coffee-machine-a-cafe/all-services',
      element: <ServicesComponent />
    },
    {
      path:'coffee-machine-a-cafe/contact-us',
      element: <Contactus />
    },
    {
      path:'coffee-machine-a-cafe/privacy',
      element: <Privacy />
    },
    {
      path:'/return',
      element: <Return />
    },
    {
      path:'coffee-machine-a-cafe/term',
      element: <Term />
    },
    {
      path:'coffee-machine-a-cafe/about-us',
      element: <Aboutus />
    },
    {
      path:'/payment/ios/mobile/:slug/:slug',
      element: <PaypalMobile />
    },
    {
      path:'/error',
      element: <Error />
      
    }
  ]);

function Routes() {
  return (
    <div>
        <RouterProvider router={router} />
    </div>
  )
}

export default Routes

import React from 'react'
import Navbar from '../Sections/Navbar'
import Footer from '../Sections/Footer'

function Aboutus() {
  return (
    <div>
      <Navbar />
        
        <Footer />
    </div>
  )
}

export default Aboutus

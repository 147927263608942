import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CreateOrderApi, PaymentSuccessApi } from "../AllAPIS";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../baseURL";
import "./../Styles/mobilescreenIos.css";

const PaypalMobile = ({
  FetchCartOnBuyButton,
  cartData,
  timing,
  address,
  getUserCordinatorRedux,
  userData,
}) => {
  let orderNo = window.location.pathname.split("/")[4];
  let userToken = window.location.pathname.split("/")[5];
  const navigation = useNavigate();
  const [orderNumber, setOrderNumber] = useState(null);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderId, setOrderId] = useState(false);
  const [show, setShow] = useState(false);
  const [oderDetails, setOrderDetails] = useState()

  useEffect(() => {
    let data = JSON.stringify();

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseURL}order-details/${orderNo}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data, "created success 0000000000000");
        setOrderDetails(response.data.data)
      })
      .catch((error) => {
        console.log(error);
        setShow(false);
      });
  }, []);

  // creates a paypal order
  const createOrder = (data, actions) => {
 
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Payment For Sav Machine Services",
            amount: {
              currency_code: "USD",
              value: oderDetails?.amount,
            },
          },
        ],
      })
      .then((orderID) => {
        console.log(orderID, "order Id ");
        setOrderId(orderID);
        return orderID;
      });
  };


  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      const { orderID } = data;

      setOrderId(orderID);
      console.log(payer, data, "payer payer payer");
      setSuccess(true);
    });
  };

 
  useEffect(() => {
    // CreateOrder(orderId)
    if (success) {
      PaymentSuccessApi(userToken, orderNo, oderDetails?.cartId	, navigation);
    }
  }, [success]);

  return (
    <>
      <div onClick={() => navigation(`/`)} className="logo-mobile">
        <img src="/img/logo.png" alt="image" />
      </div>

      <PayPalScriptProvider
        options={{
          "client-id":
            "AbN-D1kbTBuCRPoyvs8p1R_fUG2BJjpYWZZ5jWnqFmKQW1M0yO4OVFjKBfXpu4b1lq5uRccjtjJDEgKB",
        }}
      >
        <div>
          {show ? null : (
            <button
              className="btn-mobile-ios"
              type="submit"
              onClick={() => {
                setShow(true);
              }}
            >
              Click To Continue
            </button>
          )}
          {show ? (
            <PayPalButtons
              style={{ layout: "vertical" }}
              createOrder={createOrder}
              onApprove={onApprove}
            />
          ) : null}
        </div>
      </PayPalScriptProvider>
    </>
  );
};

export default PaypalMobile;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiFillUnlock } from "react-icons/ai";
import { loginCartApi } from "../AllAPIS";
import { setStoreUserToken } from "../Redux";
import { ErrorToaster } from "../Toasters";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./../Styles/forgotmodal.css";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
function LoginModal({
  setShowLoginModal,
  showLoginModal,
  setStoreUserTokenRedux,
  storeCartId
}) {
    const navigation =useNavigate()
  const [email, setEmail] = useState();
  const [password, setpassword] = useState();
  const handleClose = () => setShowLoginModal(false);

  return (
    <>
      <Modal show={showLoginModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title">Login Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
            Please enter your email and Password To Enter 
          </h5>
          <div className="forgot_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder="Enter Email"
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder="Enter Password"
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e) => setpassword(e.target.value)}
                value={password}
              />
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="forgot_button"
            onClick={() => {
              if (email === "" || password === "") {
                ErrorToaster("Fields Should not be Empty");
              } else if (emailreg.test(email)===false) {
                ErrorToaster(
                  "Email Format is not correct it Contain @ & .com"
                );
              } else if (passReg.test(password) === false) {
                ErrorToaster(
                  "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
                );
              } else {
                let loginData = {
                    email: email,
                    password: password,
                    device_token: "BNQASDFWP30KCYBER678IPFO",
                    token:'bfc04fe1-a576-4210-94d7-d70d7a6a124b'
                  };
                  loginCartApi(loginData, navigation, setStoreUserTokenRedux, storeCartId, setShowLoginModal);
              }
            }}
          >
           Login
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


const mapStateToProps = (state) => {
    return {
      getStoreUserToken: state.storeUserToken.token,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setStoreUserTokenRedux: (parameter) => {
        dispatch(setStoreUserToken(parameter));
      },
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
  


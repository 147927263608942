import { TIME_SLOT} from "../type"

//initializing state 
const initialState = {
    time : null,
    date : null,
  
}

const setTimeSlotReducer= (state = initialState, action) => {
    switch (action.type) {
        case TIME_SLOT: return {
            ...state,
            time: action.payload.time,
            date: action.payload.date,
        }
        default: return state
    }
}

export default setTimeSlotReducer;
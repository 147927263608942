import React from 'react'
import './../Styles/brands.css'
import { useTranslation } from "react-i18next";

function Brands() {
    const { t } = useTranslation();

  return (
    <div className='container py-5 brands' >
        <h2 className='text-center' >{t("Our Brands")}</h2>
       <div className="row">
         <div className="col-md-2">
            <div className="brand-img">
                <img src="/img/brands_images/2.jpg" alt="" />
            </div>
         </div>
         <div className="col-md-2">
            <div className="brand-img">
                <img src="/img/brands_images/1.jpg" alt="" />
            </div>
         </div>
         <div className="col-md-2">
            <div className="brand-img">
                <img src="/img/brands_images/2.jpg" alt="" />
            </div>
         </div>
         <div className="col-md-2">
            <div className="brand-img">
                <img src="/img/brands_images/1.jpg" alt="" />
            </div>
         </div>
         <div className="col-md-2">
            <div className="brand-img">
                <img src="/img/brands_images/2.jpg" alt="" />
            </div>
         </div>
         <div className="col-md-2">
            <div className="brand-img">
                <img src="/img/brands_images/1.jpg" alt="" />
            </div>
         </div>
       </div>
    </div>
  )
}

export default Brands

import React,{useState, useEffect} from "react";
import { PiStarOfDavidFill } from "react-icons/pi";
import { AiFillTag } from "react-icons/ai";
import { BiSolidTimeFive } from "react-icons/bi";
import { ErrorToaster } from "../Toasters";
import { BsDot } from "react-icons/bs";
import "./../Styles/allservice.css";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setUserCordinates } from "../Redux";

function AllPackages({ packages, addToCartHandle, loader,selectedPackage,getUserCordinatesRedux ,setUserCordinatesRedux}) {
  const { t } = useTranslation();
  const [cartIdLoader, setCartIdLoader] = useState(false);

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  
  function success(pos) {
    var crd = pos.coords;
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    setUserCordinatesRedux({lat:crd.latitude, lng:crd.longitude})
  }
  
  function errors(err) {
    if(err.code===1 || err.message==='User denied geolocation prompt'){
    }
    console.warn(`ERROR(---${err.code}): ${err.message} trueeeeeeeeeeee`);
  }

  const handleAllowLocation =()=>{
    if (navigator.geolocation) {
      navigator.permissions
     .query({ name: "geolocation" })
     .then(function (result) {
       if (result.state === "granted") {
         console.log(' gante location')
         // console.log(result.state);
         //If granted then you can directly call your function here
         navigator.geolocation.getCurrentPosition(success);
       } else if (result.state === "prompt") {
         console.log(' location')
         navigator.geolocation.getCurrentPosition(success, errors, options);
       } else if (result.state === "denied") {
         //If denied then you have to show instructions to enable location
         console.log('cancel location')
       }
       result.onchange = function () {
         console.log(result.state,'000000000000000000000000');
       };
     });
 } else {
   alert("Sorry Not available!");
 }
  }
  useEffect(()=>{
   
  },[])

  return (
    <div className="all-services-main">
      <div className="all-service-card">
        <div className="service-card-heading">
          <h2>{t("Packages")}</h2>
          <h3>Why SAV? </h3>
        </div>

        {
          !getUserCordinatesRedux?.lat
          ?
          <div className="no_data_in_service">
            <h3>Please Allow Your Location For Show Package</h3>
            <h6>Click on the three dots in the top right corner, next to your profile picture. Click Settings. Click Site Settings, under the Privacy and Security section. Click Location under the Permissions section</h6>
            <button onClick={handleAllowLocation} >Allow Location</button>
          </div>
          :
          packages?.length === 0 || !packages ? (
            <div className="no_data_in_service">
              <h3>No Package Available in this Service</h3>
            </div>
          ) : (
            packages?.map((item, i) => {
              return ( 
                 <div key={i} className={`service-card-middle-sections ${selectedPackage===item.type_of_service? 'd-block':'d-none'}`}>
                  <div className="row">
                    <div className="col-md-8 py-3">
                      <div className="service-card-warrenty-button">
                        <span>30 DAYS WARRANTY</span>
                      </div>
                      <div className="service-midlle-section-content mt-3">
                        <h2>{item?.package_name} </h2>
                        <h4>
                          {" "}
                          Price : {item?.regular_price} ${" "}
                          <span>({item?.base_price})</span>
                        </h4>
                        <h5>
                          <BiSolidTimeFive />2 hour 25 Minutes
                        </h5>
                        <h6>
                          <AiFillTag /> ${item?.discount} OFF
                        </h6>
                      </div>
                    </div>
                    <div className="col-md-4 d-flex flex-column justify-content-between py-3 ">
                      <div className="service-booking-details">
                        <span className="img-tag">Machine Service</span>
                        <img src={item?.image} alt="img" />
                      </div>
                      <div
                        onClick={() => {
                            setCartIdLoader(item?.id)
                          addToCartHandle(item?.id);
                          
                        }}
                        className="service-bboking-cart-button"
                      >
                        {loader && item?.id===cartIdLoader ? (
                          <div className="spinner-border" role="status"></div>
                        ) : null}
  
                        <span>{t("Add To Cart")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="service-descriptions">
                    <h2>
                      <BsDot />
                      {item?.description}{" "}
                    </h2>
                    {/* <button onClick={()=>{ FetchPackageDetailsView(item.id); console.log(item.type_of_service,'fdgfg')}} >View Details</button> */}
                  </div>
                </div>     
                
              );
            })
          )
        }

       

        {
            packages?.slice(1, 2).map((item, i) => {
              return (
               <>
                <div key={i} className={`my-4 ${selectedPackage===item.type_of_service? 'd-none':'d-block'}`}>
                <div className="no_data_in_service">
                <h3>No Package Available in this Service</h3>
              </div>
                  </div>          
                 </>
              );
            })
        }
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    setUserCordinatesRedux: (parameter) => {
      dispatch(setUserCordinates(parameter));
    },

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllPackages);


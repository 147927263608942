import React from "react";
import "./../Styles/clienttestimonial.css";
import { useTranslation } from "react-i18next";

function ClientTestimonial() {
  const { t } = useTranslation();
  return (
    <div className="container my-4 py-3">
      <div className="column">
        <div className="clienttestimonail-heading">
          <h4 className="text-center">{t("testimonial1")}</h4>
          <h1 className="text-center">
            <span> {t("What People Say About Us")}</span>
          </h1>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-3">
              <div className={`clienttestimonail-card`}>
                <img src="/img/testimonial_image/2.png" alt="image" />
                <h2>Pascal P</h2>
                <p>{t("testimonial2")}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className={`clienttestimonail-card`}>
                <img src="/img/testimonial_image/1.png" alt="image" />
                <h2>Fréderic J</h2>
                <p>
                {t("testimonial3")}
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className={`clienttestimonail-card`}>
                <img src="/img/testimonial_image/2.png" alt="image" />
                <h2>Stephane T</h2>
                <p>
                {t("testimonial4")}
                </p>
              </div>
            </div>

            <div className="col-md-3">
              <div className={`clienttestimonail-card`}>
                <img src="/img/testimonial_image/3.png" alt="image" />
                <h2>Serge B</h2>
                <p>{t("testimonial5")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientTestimonial;

import React from 'react'
import Navbar from '../Sections/Navbar'
import Footer from '../Sections/Footer'
import { useLayoutEffect } from 'react'

function Return() {
    useLayoutEffect(()=>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    },[])

  return (
    <div>
      <Navbar />
        
        <Footer />
    </div>
  )
}

export default Return

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import "./../Styles/forgotmodal.css";
function MoreContentModal({setShowLoginModal, showLoginModal}) {
    const { t } = useTranslation();
    const handleClose = () => setShowLoginModal(false);
  return (
    <>
      <Modal show={showLoginModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title">About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
          {t("aboutus_para_footer")}
          </h5>
       
        </Modal.Body>
      </Modal>
    </>
  );
}

  export default MoreContentModal;
  


import React from "react";
import {AiFillCaretRight} from 'react-icons/ai'
import "./../Styles/savmachines.css";
import { useTranslation } from "react-i18next";


function SavMachines() {
  const { t } = useTranslation();

  return (
    <div className="container my-4 py-3">
      <div className="column">
        <div className="sav-machine-heading">
          <h1 className="text-center">
            <span>{t("Why The Sav Machines")}</span>
          </h1>
          <h4 className="text-center">
          {t("workflow_para")}
          </h4>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="sav-machine-img">
                <img src="/img/men.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="work-flow-right text-white">
                <div>
                <h5>{t("You Can Rely On Us")}</h5>
                 <ul className="p-0 m-0">
                    <li className="list-group-item py-2 "  ><AiFillCaretRight className="text-dark work-flow-arrow-icons mx-2" />{t("Fast installation, maintenance and repair of your fully automatic coffee machine")}</li>
                    <li className="list-group-item py-2 "  ><AiFillCaretRight className="text-dark work-flow-arrow-icons mx-2" />{t("Configuration of the coffee machine according to your coffee taste")}</li>
                    <li className="list-group-item py-2 "  ><AiFillCaretRight className="text-dark work-flow-arrow-icons mx-2" />{t("Technical support through our hotline")}</li>
                    <li className="list-group-item py-2 "  ><AiFillCaretRight className="text-dark work-flow-arrow-icons mx-2" />{t("Remote maintenance and support thanks to telemetry module")}</li>
                 </ul>
                 {/* <button>Get Advice On Service Now <AiFillCaretRight className="text-white " /></button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SavMachines;

import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { FaClock } from "react-icons/fa";

import LocationModal from "../Modals/LocationModal";
import TimeSlotModal from "../Modals/TimeSlotModal";
import PaypalPage from "../Component/PaypalPage";
import { connect } from "react-redux";
import { fetchUserApi } from "../AllAPIS";
import "./../Styles/getlocation.css";

function GetLocation({ getAddressRedux, getTimeSlotRedux ,cartData, getUserCordinatorRedux, FetchCartOnBuyButton}) {
  const [showLocationModalModal, setShowLocationModalModal] = useState(false);
  const [showTimeSloteModalModal, setShowTimeSloteModalModal] = useState(false);
  const [userData,setUserData] = useState()

  const getUserLocationFromAPI = async () => {
    setShowLocationModalModal(true);
    //  try {
    //   await axios.get('https://ipgeolocation.abstractapi.com/v1/', {api_key: 'AIzaSyA34wL1MtQPCFqF21Bz6pd8H-WqQAeFRIE'})
    //   .then((res)=>{
    //     console.log(res)
    //   }).catch((err)=>{
    //     console.log(err,'locaaa');
    //   })

    //  } catch (error) {
    //   //  setError('Something went wrong getting Geolocation from API!')
    //   console.log(error)
    //  }
  };

  useEffect(()=>{
    fetchServicesList()
  },[])

  const fetchServicesList = () => {
    var token = localStorage.getItem("SAVMACHINETOKEN");
   if(token){
    fetchUserApi(token, (data) => {
      setUserData(data);
    });
   }
  };

  let totalCartPrice = 0;

  for (let i = 0; i < cartData.length; i++) {
    totalCartPrice += cartData[i]?.regular_price * cartData[i]?.quantity;
  }

  return (
    <>
      <LocationModal
        showLocationModalModal={showLocationModalModal}
        setShowLocationModalModal={setShowLocationModalModal}
      />
      <TimeSlotModal
        showTimeSloteModalModal={showTimeSloteModalModal}
        setShowTimeSloteModalModal={setShowTimeSloteModalModal}
      />
      <div className="get_location">
        {/* <div className="search_get_location_input">
          <BsSearch />
          <Autocomplete
            apiKey="AIzaSyA34wL1MtQPCFqF21Bz6pd8H-WqQAeFRIE"
            onPlaceSelected={(place) => {
              console.log(place);
            }}
            options={{
              types: ["(regions)"],
            }}
          />
        </div> */}
        <div
          onClick={getUserLocationFromAPI}
          className="search_get_curret_location"
        >
          <BiCurrentLocation />
          <h6 className="mx-2 my-0 p-0">
            {!getAddressRedux?.address ? "Add Address" : "Your Address"}
          </h6>
        </div>
        {!getAddressRedux?.address ? null : (
          <div className="address_full mx-2 my-0 p-0">
            <h4>
              <p>Full Address </p>
              <span>:</span>{" "}
              <span className="address_span">{getAddressRedux?.address}</span>
            </h4>
            <h4>
              <p> Near By </p>
              <span>:</span>{" "}
              <span className="address_span">{getAddressRedux?.nearBy}</span>
            </h4>
            <h4>
              <p> City </p>
              <span>:</span>{" "}
              <span className="address_span">{getAddressRedux?.city}</span>
            </h4>
            <h4>
              <p> Zip Code </p>
              <span>:</span>{" "}
              <span className="address_span">{getAddressRedux?.zipCode}</span>
            </h4>
            <h4>
              <p> Country </p>
              <span>:</span>{" "}
              <span className="address_span">{getAddressRedux?.country}</span>
            </h4>
          </div>
        )}

        <div></div>
      </div>
      <div className="get_time_slot_main">
        <div  onClick={() => {
            setShowTimeSloteModalModal(true);
          }} className="get_time_slot">
          <FaClock />
          <h6 className="mx-2 my-0 p-0">Time Slot</h6>
        </div>

        {!getTimeSlotRedux?.time ? null : (
          <>
            <div className="address_full mx-2 my-4 p-0">
              <h4>
                <p>Time </p>
                <span>:</span>{" "}
                <span className="address_span">{getTimeSlotRedux?.time}</span>
              </h4>
              <h4>
                <p>Date</p>
                <span>:</span>{" "}
                <span className="address_span">{getTimeSlotRedux?.date}</span>
              </h4>
            </div>
           
          </>
        )}

        {
          !getAddressRedux?.address || !getTimeSlotRedux?.time
          ?
          null
          :
          <PaypalPage FetchCartOnBuyButton={FetchCartOnBuyButton} timing ={getTimeSlotRedux} address={getAddressRedux} cartData={cartData} getUserCordinatorRedux={getUserCordinatorRedux} userData={userData} totalCartPrice={totalCartPrice} />
        }
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getAddressRedux: state.address,
    getTimeSlotRedux: state.timeSlot,
    getUserCordinatorRedux: state.userCordinates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GetLocation);

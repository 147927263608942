import { STORE_TOKEN} from "../type"

//initializing state 
const initialState = {
    token: null,
}

const storeUserTokenReducer= (state = initialState, action) => {
    switch (action.type) {
        case STORE_TOKEN: return {
            ...state,
            token: action.payload,
        }
        default: return state
    }
}

export default storeUserTokenReducer;
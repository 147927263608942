import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineMail } from "react-icons/ai";
import ForgotCodeModal from "./ForgotCodeModal";
import { forgotApi } from "../AllAPIS";
import "./../Styles/forgotmodal.css";
import { useTranslation } from "react-i18next";


function ForgotModal({ setShowForgotModal, showForgotModal }) {
    const [ showForgotCodeModal, setShowForgotCodeModal] = useState(false)
    const [email, setEmail] = useState()
  const handleClose = () => setShowForgotModal(false);
  const { t } = useTranslation();


  return (
    <>
    <ForgotCodeModal showForgotCodeModal={showForgotCodeModal} setShowForgotCodeModal={setShowForgotCodeModal} />
      <Modal show={showForgotModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title" >Reset your password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
            Please enter your email and we will send on OPT code in next step to
            reset your password
          </h5>
          <div className="forgot_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiOutlineMail />
              </InputGroup.Text>
              <Form.Control
                placeholder={t("Enter Your Email")}
                aria-label="email"
                aria-describedby="basic-addon1"
                onChange={(e)=>setEmail(e.target.value)}
                value={email}
              />
            </InputGroup>
          </div>
        </Modal.Body> 
        <Modal.Footer>
          <button className="forgot_button" onClick={()=>{
        //    setShowForgotCodeModal(true)
           forgotApi(email , setShowForgotCodeModal, setShowForgotModal)
          }}>
            Send Code
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ForgotModal;

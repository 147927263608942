import React, { useState, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineMail, AiFillPhone, AiOutlineUser } from "react-icons/ai";
import { FaAddressCard } from "react-icons/fa";
import { BsFillCameraFill, BsFillCalendar2DateFill } from "react-icons/bs";
import { updateProfileApi, DeleteAccountApi } from "../AllAPIS";
import { setStoreUserToken } from "../Redux";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import "./../Styles/profile.css";
import { useTranslation } from "react-i18next";
import { logoutApi, fetchUserApi } from "../AllAPIS";

function ProfileModal({
  setShowProfileModal,
  showProfileModal,
  setStoreUserTokenRedux,
  getStoreUserToken,
  getSelectedLanguage,
}) {
  const { t } = useTranslation();
  const handleClose = () => setShowProfileModal(false);
  const navigation = useNavigate();
  const [userData, setUserData] = useState();

  const [userName, setUserName] = useState(userData?.name);
  const [phone, setPhone] = useState(userData?.phone);
  const [address, setAddress] = useState(userData?.address);
  const [dob, setDob] = useState(userData?.date_of_birth);
  const [profileImage, setProfileImage] = useState(userData?.image);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const [loader, setLoader] = useState(false);

  useLayoutEffect(() => {
    fetchServicesList();
  }, [loader, getSelectedLanguage]);

  const fetchServicesList = () => {
    var token = localStorage.getItem("SAVMACHINETOKEN");
    if (token) {
      fetchUserApi(token, (data) => {
        setUserData(data);
        setUserName(data?.name);
        setPhone(data?.phone);
        setAddress(data?.address);
        setDob(data?.date_of_birth);
        setProfileImage(data?.image);
      });
    }
  };

  const handleUpdateProfile = () => {
    var token = localStorage.getItem("SAVMACHINETOKEN");
    updateProfileApi(
      profileImage,
      userName,
      phone,
      address,
      dob,
      token,
      setLoader
    );
  };
  useLayoutEffect(() => {
    setUserName(userData?.name);
    setPhone(userData?.phone);
    setAddress(userData?.address);
    setDob(userData?.date_of_birth);
    setProfileImage(userData?.image);
  }, []);

  return (
    <>
      <ChangePassword
        openChangePassword={openChangePassword}
        setOpenChangePassword={setOpenChangePassword}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showProfileModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="profile_title">
            {"profile_heading"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="profile_heading">{t("profile_sub_heading")}</h5>
          <div className="profile_image">
            <img src={userData?.image} alt="profile-img" />
            <label htmlFor="upload_img">
              <BsFillCameraFill />
              <input
                id="upload_img"
                type="file"
                className="form-control "
                onChange={(event) => setProfileImage(event.target.files[0])}
              />
            </label>
          </div>
          <div className="profile_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiOutlineMail />
              </InputGroup.Text>
              <Form.Control
                disabled
                aria-label="email"
                aria-describedby="basic-addon1"
                value={userData?.email}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiOutlineUser />
              </InputGroup.Text>
              <Form.Control
                placeholder={t("name_input")}
                value={userName}
                aria-label="email"
                aria-describedby="basic-addon1"
                onChange={(e) => setUserName(e.target.value)}
              />
            </InputGroup>
          </div>
          <div className="profile_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillPhone />
              </InputGroup.Text>
              <Form.Control
                placeholder="+1 ##########"
                aria-label="phone"
                aria-describedby="basic-addon1"
                type="number"
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                  } else {
                    setPhone(e.target.value);
                  }
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FaAddressCard />
              </InputGroup.Text>
              <Form.Control
                placeholder="House No./Street No./Area/Country"
                aria-label="email"
                aria-describedby="basic-addon1"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="profile_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <BsFillCalendar2DateFill />
              </InputGroup.Text>
              <Form.Control
                placeholder="Date of Birth(DD-MM-YYYY)"
                aria-label="phone"
                aria-describedby="basic-addon1"
                type="date"
                value={dob}
                onChange={(e) => {
                  if (e.target.value.length > 10) {
                  } else {
                    setDob(e.target.value);
                  }
                }}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <div className="d-flex justify-content-around profile_buttons ">
                <button
                  onClick={() => {
                    setOpenChangePassword(true);
                    setShowProfileModal(false);
                  }}
                >
                  {t("change_pass")}
                </button>
                <button
                  className="mx-2"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure?",
                      text: "You wnat to delete account!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete it!",
                    }).then((result) => {
                      var token = localStorage.getItem("SAVMACHINETOKEN");
                      DeleteAccountApi(
                        token,
                        navigation,
                        setStoreUserTokenRedux
                      );
                      if (result.isConfirmed) {
                        Swal.fire(
                          "Deleted!",
                          "Your account has been deleted.",
                          "success"
                        );
                      }
                    });
                  }}
                >
                  {t("delete_account")}
                </button>
              </div>
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="profile_button" onClick={handleUpdateProfile}>
            {t("save_change")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
    getStoreUserToken: state.storeUserToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreUserTokenRedux: (parameter) => {
      dispatch(setStoreUserToken(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);

import React,{useState} from 'react'
import {FaFacebookF} from 'react-icons/fa'
import {AiFillInstagram} from 'react-icons/ai'
import {AiFillTwitterSquare} from 'react-icons/ai'
import {AiFillYoutube} from 'react-icons/ai'
import './../Styles/footer.css'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import ProfileModal from '../Modals/ProfileModal'
import MoreContentModal from '../Modals/MoreContentModal'


function Footer() {
    const { t } = useTranslation();
    const navigation =useNavigate()
    const [showProfileModal,setShowProfileModal]= useState(false)
    const [ showLoginModal, setShowLoginModal] = useState(false)

  return (
 <>
 <MoreContentModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} />
 <ProfileModal showProfileModal={showProfileModal} setShowProfileModal={setShowProfileModal} />
    <div className='footer' >
      <div className="container">
         <div className="row">
            <div className="col-md-4 footer-left">
                <div className="footer_logo">
                    <img src="/img/footer-logo.png" alt="footer-image" />
                </div>
                <span >{t("aboutus_para_footer1")} <span className='more_footer' onClick={()=>setShowLoginModal(true)} >{t("More")}...</span></span>
                {/* <ul className='mt-3 p-0 d-flex justify-content-between w-50' >
                    <li className='' ><FaFacebookF/></li>
                    <li className='' ><AiFillInstagram/></li>
                    <li className='' ><AiFillTwitterSquare/></li>
                    <li className='' ><AiFillYoutube/></li>
                </ul> */}
            </div>

                <div className="col-md-8 footer-right">
                    <div className="row">
                         <div className="col-md-4">
                            <h4 className='mb-4' >{t("quick_link")}</h4>
                            <ul className="m-0 p-0 ">
                                {/* <li className='list-group-item py-1' role='button' onClick={()=>navigation('/about')}  >{t("About us")}</li> */}
                                <li className='list-group-item py-1' role='button' onClick={()=>navigation('/coffee-machine-a-cafe/contact-us')}  >{t("Contact us")}</li>
                                {/* <li className='list-group-item py-1' role='button' onClick={()=>navigation('/return')}  >{t("Return")}</li> */}
                                <li className='list-group-item py-1' role='button' onClick={()=>navigation('/coffee-machine-a-cafe/term')}  >{t("Term")}</li>
                                <li className='list-group-item py-1' role='button' onClick={()=>navigation('/coffee-machine-a-cafe/privacy')}  >{t("Privacy")}</li>
                            </ul>
                         </div>
                       
                         <div className="col-md-3">
                         <h4 className='mb-4' >{t("Pages")}</h4>
                            <ul className="m-0 p-0 ">
                                <li className='list-group-item py-1' role='button' onClick={()=>{
                                      var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
                                      if (tokenSav === null || tokenSav === undefined) {
                                        navigation('/login')
                                      } else{
                                        setShowProfileModal(true)
                                      }
                                  
                                    }} > {t("Account")} </li>

                                <li className='list-group-item py-1' role='button' onClick={()=>{
                                      var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
                                      if (tokenSav === null || tokenSav === undefined) {
                                        navigation('/login')
                                      } else{
                                        navigation('/coffee-machine-a-cafe/booking/details')
                                      }
                                   
                                    }} > {t("Orders")} </li>
                                <li className='list-group-item py-1' role='button' onClick={(e) => navigation("/signup-technician")} > {t("Register As a Technician")} </li>
                            </ul>
                            
                         </div>

                         <div className="col-md-5 footer-left">
                          <h4 className='mb-4' >{t("Social Media Links")}</h4>
                            {/* <ul className="m-0 p-0 ">
                                <li className='list-group-item py-1' role='button' >{t("Whatsapp")}</li>
                                <li className='list-group-item py-1' role='button' >{t("Telegram")}</li>
                                <li className='list-group-item py-1' role='button' >{t("Ticketing")}</li>
                            </ul> */}

                             <ul className='mt-3 p-0 d-flex' >
                                <li className='mx-2' ><FaFacebookF/></li>
                                <li className='mx-2' ><AiFillInstagram/></li>
                                <li className='mx-2' ><AiFillTwitterSquare/></li>
                                <li className='mx-2' ><AiFillYoutube/></li>
                            </ul>

                         </div>
                    </div>
                </div>
            
         </div>
      </div>
    </div>

    <div className="footer-bottom">
        <h5><span>© 2023 SAV MACHINES</span> All Rights Reserved.</h5>
    </div>
 </>
  )
}

export default Footer

import React,{useState, useEffect} from 'react'
import {MdLocationOn} from 'react-icons/md'
import { fetchUserApi } from '../AllAPIS'
import './../Styles/bookingdetails.css'

function BookingDetails() {

    const [userData, setUserData] = useState()
    useEffect(()=>{
        var token = localStorage.getItem('SAVMACHINETOKEN')
       if(token){
        fetchUserApi(token , (data)=>{
            setUserData(data)
        })
       }
    },[])
  
  return (
    <>
      <div className="booking_details">
          <div className="container">
             <div className="row d-flex align-items-center">
                 <div className="col-2">
                     <div className="location_icon_booking_details">
                         <MdLocationOn />
                     </div>
                 </div>
                 <div className="col-10">
                      <div className="send_booking_details">
                         <h5 className='p-0 m-0'>Send Booking Details to </h5>
                         <p className='p-0 m-0' >{userData?.email}</p>
                      </div>
                 </div>
             </div>
          </div>
      </div>
    </>
  )
}

export default BookingDetails

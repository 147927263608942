import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
// import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { setTimeSlot } from "../Redux";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./../Styles/forgotmodal.css";
import { Form } from "react-bootstrap";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function TimeSlotModal({
  showTimeSloteModalModal,
  setShowTimeSloteModalModal,
  setTimeSlotRedux,
}) {

  const handleClose = () => setShowTimeSloteModalModal(false);
 
  const [slectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [timeSlotes] = useState([
    "10:00 AM",
    "11:30 AM",
    "01:00 AM",
    "02:30 PM",
    "04:00 PM",
    "05:30 PM",
  ]);

  const handleSubmit =()=>{
    setTimeSlotRedux({time:slectedTimeSlot, date:selectedDate})
    setShowTimeSloteModalModal(false)
    console.log(selectedDate,'dfjsdhfkjsd')
  }


  // console.log(selectedDate.toLocaleDateString(),'selectedDateselectedDateselectedDate')

  return (
    <>
      <Modal
        size="lg"
        centered={true}
        show={showTimeSloteModalModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title">
            Choose Time for Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="dob">
                  <Form.Label className="d-block" >Select Date</Form.Label>
                  <DatePicker
                    minDate={new Date()}
                    value={selectedDate}
                    onChange={(date)=>setSelectedDate(date.toLocaleDateString() )}
                    />
                   
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Row>
                  {timeSlotes?.map((item, i) => {
                    return (
                      <Col key={i} xs={6} md={6}>
                        <div onClick={()=>setSelectedTimeSlot(item)} className={`time_slot my-2 ${item===slectedTimeSlot ?'time_slot_selected':''}`}>
                          <span>{item}</span>
                        </div>
                      </Col>
                    );
                  })}

                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleSubmit} className="forgot_button">Save</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTimeSlotRedux: (parameter) => {
      dispatch(setTimeSlot(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlotModal);

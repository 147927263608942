import React,{useEffect, useState} from 'react'
import {MdLocationOn} from 'react-icons/md'
import {AiFillPhone} from 'react-icons/ai'
import {FaFax} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import Navbar from '../Sections/Navbar'
import Footer from '../Sections/Footer'
// import { AddContactFormApi } from '../Apis'
import './../Styles/contactus.css'
import { ContactUsApi } from '../AllAPIS'
import { useTranslation } from "react-i18next";
    

function Contactus() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[])

    const handleSubmit =(e)=>{
      e.preventDefault()
      let formdata ={
        name:name,
        email:email,
        phone:phone,
        message:message
      }
    ContactUsApi(formdata, setEmail, setPhone, setName, setMessage)
    }
  return (
    <div className='contact_us_main'>
    <Navbar/>
     <div className="container my-4">
       <div className="row justify-content-center">
            <div className="col-md-3">
                <div className='contact-card-box' >
                    <MdLocationOn/>
                    <h3 className='text-center' >OUR MAIN OFFICE</h3>
                    <h6 className='text-center'>60 ,rue  François 1er 75008. Paris</h6>
                </div>
            </div>
            <div className="col-md-3">
            <div className='contact-card-box' >
                    <AiFillPhone/>
                    <h3 className='text-center'>PHONE NUMBER</h3>
                    <h6 className='text-center m-0'>0764016217</h6>
                </div>
            </div>
            <div className="col-md-3">
            <div className='contact-card-box' >
                    <MdEmail/>
                    <h3 className='text-center'>EMAIL</h3>
                    <h6 className='text-center'>savmachineacafe@free.fr</h6>
                </div>
            </div>
       </div>
       <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
              <form onSubmit={handleSubmit}>
                <div className="contact-form-box">
                  <h2>{t("Contact Us")}</h2>
                  <div>
                  <input type="text" placeholder={t("contact2")} value={name} onChange={(e)=>setName(e.target.value)} required />
                  </div>
                  <div>
                  <input required type="number" placeholder={t("contact3")} value={phone} onChange={(e)=>{
                    if(e.target.value.length>10){

                    }else{
                      setPhone(e.target.value)
                    }
                  }} />
                  </div>
                  <div>
                  <input required type="email" placeholder={t("contact4")} value={email} onChange={(e)=>setEmail(e.target.value)} />
                  </div>
                  <textarea required placeholder={t("contact5")} value={message} onChange={(e)=>setMessage(e.target.value)} id="w3review" name="w3review" rows="4" cols="50"></textarea>
                  <button type='submit' >{t("contact6")}</button>
                </div>
              </form>
          </div>
          <div className="col-md-3"></div>
       </div>
         
     </div>
     <Footer/>
    </div>
  )
}

export default Contactus

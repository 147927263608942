import {createStore, combineReducers , applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import storeUserTokenReducer from './reducers/storeUserTokenReducer';
import setUserCordinatsReducer from './reducers/setUserCordinatsReducer';
import setSelectedLanguageReducer from './reducers/setSelectedLanguageReducer';
import setAddressReducer from './reducers/setAddressReducer';
import setTimeSlotReducer from './reducers/setTimeSlotReducer'
import setUpdatePageGlobalReducer from './reducers/setUpdatePageGlobalReducer';
import setAllServicesReducers from './reducers/setAllServicesReducers';


export const rootReducer =  combineReducers({
 storeUserToken:storeUserTokenReducer,
 userCordinates:setUserCordinatsReducer,
 language:setSelectedLanguageReducer,
 address:setAddressReducer,
 timeSlot:setTimeSlotReducer,
 updatePageGlobal:setUpdatePageGlobalReducer,
 services:setAllServicesReducers
});
  
// Passing burgerReducer to createStore
const store=createStore(rootReducer, applyMiddleware(thunk));
  
export default store;
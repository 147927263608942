import React,{useEffect, useState} from 'react'
import Navbar from '../Sections/Navbar'
import Banner from '../Sections/Banner'
import Services from '../Sections/Services'
import Offers from '../Sections/Offers'
import WorkFlow from '../Sections/WorkFlow'
import SavMachines from '../Sections/SavMachines'
import ClientTestimonial from '../Sections/ClientTestimonial'
import FAQ from '../Sections/FAQ'
import Brands from '../Sections/Brands'
import NewsLatter from '../Sections/NewsLatter'
import Footer from '../Sections/Footer'
import { useLayoutEffect } from 'react'
import { fetchServicesListApi, fetchUserApi } from "../AllAPIS";
import { useNavigate } from 'react-router-dom'
import { setStoreUserToken , setUserCordinates , setSelectedLanguages, setAllServiceAction} from '../Redux'
import { connect } from 'react-redux'


var token = localStorage.getItem('SAVMACHINETOKEN')
function Home({setStoreUserTokenRedux, getSelectedLanguage,  setSelectedLanguagesRedux, setUserCordinatesRedux, setAllServiceRedux}) {
  const navigation =useNavigate()
  const [allServices, setAllServices] = useState([]);
  const [page, setPage] = useState(1);
  const [userData , setUserData] = useState()
  const [loader, setLoader] = useState(false)

  const[faqs] = useState([
    {
        id: 1,
        header: "Why choose SAV Machines?",
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
    },
    {
        id: 2,
        header: "What are the services offered by SAV Machines?",
        text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `
    },
    {
        id: 3,
        header: "SAV Machines India Pvt. Ltd. is a complete coffee solutions company and",
        text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`
    },
    {
        id: 4,
        header: "Does SAV Machine have its own products?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    },
    {
        id: 5,
        header: "Is professional barista training helpful?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    }
])

  useEffect(()=>{
    // setStoreUserTokenRedux(token)
  },[token])
 

  useLayoutEffect(() => {
    fetchServicesList();
  }, [ getSelectedLanguage]);

  const fetchServicesList = () => {
    var tokenSav = localStorage.getItem('SAVMACHINETOKEN')
    fetchServicesListApi(page,setLoader, tokenSav,getSelectedLanguage,  (data) => {
      setAllServices(data);
      setAllServiceRedux(data)
    });
    var token = localStorage.getItem("SAVMACHINETOKEN");
    if(token){
      fetchUserApi(token, (data) => {
        setUserData(data);
      });
    }
  
  };
  
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  
  function success(pos) {
    var crd = pos.coords;
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    setUserCordinatesRedux({lat:crd.latitude, lng:crd.longitude})
  }
  
  function errors(err) {
    if(err.code===1 || err.message==='User denied geolocation prompt'){
    //  navigation("/error")
    }
    console.warn(`ERROR(---${err.code}): ${err.message} trueeeeeeeeeeee`);
  }

  useEffect(()=>{
    if (navigator.geolocation) {
         navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log(' gante location')
            // console.log(result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            console.log(' location')
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
            console.log('cancel location')
          }
          result.onchange = function () {
            console.log(result.state,'000000000000000000000000');
          };
        });
    } else {
      alert("Sorry Not available!");
    }
  },[])



  return (
    <div>
       <Navbar allServices={allServices} userData={userData} setLoader={setLoader} />
       <Banner/>
       {
        loader
        ?
        <div className="loader-main">
        <div
          className="spinner-border"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only"></span>
        </div>
      </div>
      :
      <Services allServices={allServices} />
       }
      
       <Offers/>
       <WorkFlow/>
       <SavMachines/>
       <ClientTestimonial/>
       <FAQ faqs={faqs}/>
       <Brands/>
       <NewsLatter/>
       <Footer/>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreUserTokenRedux: (parameter) => {
      dispatch(setStoreUserToken(parameter));
    },
    setUserCordinatesRedux: (parameter) => {
      dispatch(setUserCordinates(parameter));
    },
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
    setAllServiceRedux: (parameter) => {
      dispatch(setAllServiceAction(parameter));
    },
    // 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

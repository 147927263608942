import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiFillUnlock } from "react-icons/ai";
import { ChnagePasswordApi } from "../AllAPIS";
import { ErrorToaster } from "../Toasters";
import "./../Styles/forgotmodal.css";
import { useTranslation } from "react-i18next";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

function ChangePassword({ openChangePassword,setOpenChangePassword }) {
  const { t } = useTranslation();
  const [oldpassword, setoldPassword] = useState()
    const [password, setPassword] = useState()
    const [nPassword, setNPassword] = useState()
  const handleClose = () => setOpenChangePassword(false);

  return (
    <>
      <Modal centered show={openChangePassword} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title" >{t("ChangePassword")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
            Please enter your old Password and New Password For Change Your Password
          </h5>
          <div className="forgot_form my-4">
          <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder={t("enter_old_pass")}
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e)=>setoldPassword(e.target.value)}
                value={oldpassword}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder={t("enter_new_pass")}
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e)=>setPassword(e.target.value)}
                value={password}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder={t("enter_new_c_pass")}
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e)=>setNPassword(e.target.value)}
                value={nPassword}
              />
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="forgot_button" onClick={()=>{
            var token = localStorage.getItem("SAVMACHINETOKEN");
            let formdata ={
                "old_password":oldpassword,
                "new_password":nPassword,
                "confirm_password":password
            }
            if(password==='' || nPassword===''||oldpassword===''){
                ErrorToaster('Fields Should not be Empty')
            } else if(password!==nPassword){
                ErrorToaster('Your New Password and Confirm New Password is not same')
            }else if (passReg.test(password) === false) {
                ErrorToaster(
                  "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters" );
              }else{
                ChnagePasswordApi(token,formdata, setoldPassword,setNPassword, setPassword )
            }
             
          }}>
         Change Password
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChangePassword;

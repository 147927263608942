import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CreateOrderApi, PaymentSuccessApi } from "../AllAPIS";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../baseURL";

const PaypalPage = ({
  FetchCartOnBuyButton,
  cartData,
  timing,
  address,
  getUserCordinatorRedux,
  userData,
}) => {
  const navigation =useNavigate()
  const [orderNumber, setOrderNumber] = useState(null);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderId, setOrderId] = useState(false);
  const [show, setShow] = useState(false);

  // creates a paypal order
  const createOrder = (data, actions) => {
    let totalCartPrice = 0;

    for (let i = 0; i < cartData.length; i++) {
      totalCartPrice += cartData[i]?.regular_price * cartData[i]?.quantity;
    }

    return actions.order
      .create({
        purchase_units: [
          {
            description: "Payment For Sav Services",
            amount: {
              currency_code: "USD",
              value: totalCartPrice,
            },
          },
        ],
      })
      .then((orderID) => {
        console.log(orderID, "order Id ");
        setOrderId(orderID);
        return orderID;
      });
  };

  const CreateOrder = (orderID) => {
    // for (let i = 0; i < cartData.length; i++) {
    //   var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    //   let formdata = {
    //     cart_id: cartData[i]?.cart_id,
    //     latitude: getUserCordinatorRedux?.lat,
    //     longitude: getUserCordinatorRedux?.lng,
    //     address: address?.address,
    //     nearby: address?.nearBy,
    //     city: address?.city,
    //     zipcode: address?.zipCode,
    //     country: address?.country,
    //     email: userData?.email,
    //     contact_number: userData?.phone,
    //     time_slot: timing?.time,
    //     date_of_service: timing?.date,
    //     transactionID: orderID,
    //   };
    //   console.log(formdata,'formdataformdataformdata')
    //   CreateOrderApi(tokenSav, formdata, setOrderNumber);
    // }
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      const { orderID } = data;

      setOrderId(orderID);
      console.log(payer, data, "payer payer payer");
      setSuccess(true);
    });
  };

  const onError = (data, actions) => {
    console.log(data, "data error", actions);
    setErrorMessage("An Error occured with your payment");
  };

  useEffect(() => {
    // CreateOrder(orderId)
    if (success ) {
      PaymentSuccessApi(orderNumber,cartData[0]?.cart_id, navigation);
    }
  }, [success]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AbN-D1kbTBuCRPoyvs8p1R_fUG2BJjpYWZZ5jWnqFmKQW1M0yO4OVFjKBfXpu4b1lq5uRccjtjJDEgKB",
      }}
    >
      <div>
        {show ? null : (
          <button type="submit" onClick={() =>{
            for (let i = 0; i < cartData.length; i++) {
              var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
              let formdata = {
                cart_id: cartData[i]?.cart_id,
                latitude: getUserCordinatorRedux?.lat,
                longitude: getUserCordinatorRedux?.lng,
                address: address?.address,
                nearby: address?.nearBy,
                city: address?.city,
                zipcode: address?.zipCode,
                country: address?.country,
                email: userData?.email,
                contact_number: userData?.phone,
                time_slot: timing?.time,
                date_of_service: timing?.date,
                transactionID: orderId,
              };
              
              // CreateOrderApi(tokenSav, formdata, setOrderNumber, (data) => {
              //   console.log(data,'order data---------------')
              //   setOrderNumber(data)
              // });
        
              let data = JSON.stringify(formdata);
          
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${baseURL}create-order`,
                headers: { 
                  'Content-Type': 'application/json', 
                  'Authorization': `Bearer ${tokenSav}` },
                data : data
              };
              
              axios.request(config)
              .then((response) => {
                setShow(true)
                console.log(response.data, 'created success 0000000000000');
                FetchCartOnBuyButton()
                setOrderNumber(response.data.order_number)
            
              })
              .catch((error) => {
                console.log(error);
                setShow(false)
              });
            }
            }}>
            Buy Now
          </button>
        )}
        {show ? (
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        ) : null}
      </div>
    </PayPalScriptProvider>
  );
};

export default PaypalPage;

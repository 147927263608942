import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiFillUnlock } from "react-icons/ai";
import { resetPasswordApi } from "../AllAPIS";
import { ErrorToaster } from "../Toasters";
import "./../Styles/forgotmodal.css";
import { useTranslation } from "react-i18next";

let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

function NewPasswordModal({ setShowNewPasswordModal, showNewPasswordModal, resetToken }) {
  const { t } = useTranslation();
    const [password, setPassword] = useState()
    const [nPassword, setNPassword] = useState()
  const handleClose = () => setShowNewPasswordModal(false);

  return (
    <>
      <Modal show={showNewPasswordModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title" >Enter New Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
            Please enter your email and we will send on OPT code in next step to
            reset your password
          </h5>
          <div className="forgot_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder='Enter Your New Password'
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e)=>setPassword(e.target.value)}
                value={password}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiFillUnlock />
              </InputGroup.Text>
              <Form.Control
                placeholder="Enter Confirm New Password"
                aria-label="password"
                aria-describedby="basic-addon1"
                onChange={(e)=>setNPassword(e.target.value)}
                value={nPassword}
              />
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="forgot_button" onClick={()=>{
            if(password==='' || nPassword===''){
                ErrorToaster('Fields Should not be Empty')
            } else if(password!==nPassword){
                ErrorToaster('Your New Password and Confirm New Password is not same')
            }else if (passReg.test(password) === false) {
                ErrorToaster(
                  "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters" );
              }else{
                resetPasswordApi(resetToken, password,nPassword, setShowNewPasswordModal )
            }
             
          }}>
         Change Password
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewPasswordModal;

import axios from "axios";
import { baseURL } from "./baseURL";
import { SuccessToaster, ErrorToaster } from "./Toasters";

export const registrationApi = (registrationData, navigation,setShowAppModal) => {
  var data = JSON.stringify(registrationData);

  var config = {
    method: "post",
    url: `${baseURL}register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(response.data,'response.dataresponse.data')
      if(response.data.data.user.role==='3'){
        setShowAppModal(true)
      } else{
        if (response.data.status === "failed") {
          ErrorToaster(response.data.message);
        } else {
          SuccessToaster(response.data.message);
          navigation("/login");
        }
      }
      
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const loginApi = (loginData, navigation, setStoreUserTokenRedux) => {
  var data = JSON.stringify(loginData);

  var config = {
    method: "post",
    url: `${baseURL}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        localStorage.setItem("SAVMACHINETOKEN", response.data.data.token);
        setStoreUserTokenRedux(response.data.data.token);
        SuccessToaster(response.data.message);
        navigation("/");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const loginCartApi = (
  loginData,
  navigation,
  setStoreUserTokenRedux,
  storeCartId,
  setShowLoginModal
) => {
  var data = JSON.stringify(loginData);

  var config = {
    method: "post",
    url: `${baseURL}login`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        localStorage.setItem("SAVMACHINETOKEN", response.data.data.token);
        setStoreUserTokenRedux(response.data.data.token);
        SuccessToaster(response.data.message);
        setShowLoginModal(false);

        //// cart api

        let data = JSON.stringify({
          quantity: 1,
          package_id: storeCartId,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseURL}add-to-cart`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response?.data?.data.token}`,
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            if (response.data.status === "success") {
              // setLoader(false);
              SuccessToaster(response.data.message);
            } else {
              ErrorToaster(response.data.message);
            }
            // console.log(JSON.stringify(response.data));
            // setLoader(false);
          })
          .catch((error) => {
            console.log(error);
            // setLoader(false);
            ErrorToaster("Something Went Wrong With Add To Cart");
          });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const fetchUserApi = (token, callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-user-details`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.status === "success") {
        callback(response.data.data.user);
      } else {
        callback([]);
      }
      // console.log(response.data,'fetch');
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateProfileApi = (
  image,
  name,
  phone,
  address,
  dob,
  token,
  setLoader
) => {
  setLoader(true)
  let data = new FormData();
  data.append("name", name);
  data.append("contact_number", phone);
  data.append("address", address);
  data.append("date_of_birth", dob);
  data.append("image", image);

  
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}profile-update`,
    headers: {
      Authorization: `Bearer ${token}`
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      setLoader(false)
      if(response.data.status==='failed'){
        ErrorToaster(response.data.message)
      } else{
        SuccessToaster(response.data.message)
      }
    })
    .catch((error) => {
      ErrorToaster('Something Went Wrong')
    });
};

export const logoutApi = (
  getStoreUserToken,
  navigation,
  setStoreUserTokenRedux
) => {
  var data = JSON.stringify({});

  var config = {
    method: "get",
    url: `${baseURL}logout`,
    headers: {
      Authorization: `Bearer ${getStoreUserToken}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      if (response.data.status === "failed") {
        ErrorToaster(response.data.message);
      } else {
        setStoreUserTokenRedux(null);
        localStorage.removeItem("SAVMACHINETOKEN");
        SuccessToaster(response.data.message);
        navigation("/");
      }
    })
    .catch(function (error) {
      // console.log(error);
    });
};

export const forgotApi = (
  email,
  setShowForgotCodeModal,
  setShowForgotModal
) => {
  let data = JSON.stringify({
    username: email,
    token:'bfc04fe1-a576-4210-94d7-d70d7a6a124b'
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}forgot-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.status === "success") {
        setShowForgotCodeModal(true);
        setShowForgotModal(false);
        SuccessToaster(response.data.message);
      } else {
        ErrorToaster(response.data.message);
      }
    })
    .catch((error) => {
      // console.log(error);
      ErrorToaster("Something Went Wrong");
    });
};

export const otpApi = (
  otp,
  setShowNewPasswordModal,
  setShowForgotCodeModal,
  setResetToken
) => {
  let data = JSON.stringify({
    otp: otp,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}otp-verification`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      if (response.data.status === "success") {
        setResetToken(response.data.data.token);
        setShowNewPasswordModal(true);
        setShowForgotCodeModal(false);
        SuccessToaster(response.data.message);
      } else {
        ErrorToaster(response.data.message);
      }
    })
    .catch((error) => {
      // console.log(error);
      ErrorToaster("Something Went Wrong");
    });
};

export const resetPasswordApi = (
  token,
  password,
  nPassword,
  setShowNewPasswordModal
) => {
  let data = JSON.stringify({
    token: token,
    newPassword: password,
    confirmPassword: nPassword,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}reset-password`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      if (response.data.status === "success") {
        setShowNewPasswordModal(false);
        SuccessToaster(response.data.message);
      } else {
        ErrorToaster(response.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      ErrorToaster("Something Went Wrong!");
    });
};

export const fetchServicesListApi = (
  page,
  setLoader,
  token,
  getSelectedLanguage,
  callback
) => {
  let data = JSON.stringify({
    page: 1,
    lang: getSelectedLanguage,
  });
  setLoader(true)
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-service-list`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      setLoader(false)
      if (response.data.status === "success") {
        // console.log(JSON.stringify(response.data));
        callback(response.data.data.service_list);
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
      setLoader(false)
    });
};

export const fetchServiceSingleApi = (id,getSelectedLanguage, token,setSpinner, callback) => {
  setSpinner(true)
  let data = JSON.stringify({
    category_id: id,
    lang:getSelectedLanguage
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-service`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      setSpinner(false)
      callback(response.data.data.service);
    })
    .catch((error) => {
      console.log(error);
      setSpinner(false)
    });
};

export const fetchPackagesListApi = (
  id,
  lat,
  lng,
  page,
  getSelectedLanguage,
  callback
) => {
  let data = JSON.stringify({
    page: page,
    latitude: lat,
    longitude: lng,
    category_id: id,
    lang: getSelectedLanguage,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-service-packages`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data?.service_packages);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const FetchServicDetailsApi=(id, token,lang, callback)=>{
  let data = JSON.stringify({
    "lang": lang
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-service-package-details/${id}`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`},
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(response.data,'jjjjj');
    callback(response.data)
  })
  .catch((error) => {
    console.log(error);
  });
}

export const addToCartApi = (id, token, setLoader) => {
  setLoader(true);
  let data = JSON.stringify({
    quantity: 1,
    package_id: id,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}add-to-cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if (response.data.status === "success") {
        setLoader(false);
        SuccessToaster(response.data.message);
      } else {
        ErrorToaster(response.data.message);
      }
      // console.log(JSON.stringify(response.data));
      setLoader(false);
    })
    .catch((error) => {
      setLoader(false);
      ErrorToaster("Something Went Wrong With Add To Cart");
    });
};

export const fetchCartApi = (token, getSelectedLanguage, page, callback) => {
  let data = JSON.stringify({
    page: page,
    lang: getSelectedLanguage,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      if (response.data.status === "success") {
        callback(response.data.data.cart_list);
      } else {
        callback([]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateCartApi = (token, quantity, id, setLoader) => {
  setLoader(true);
  let data = JSON.stringify({
    quantity: quantity,
    cart_id: id,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURL}update-cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log(JSON.stringify(response.data));
      if (response.data.status === "success") {
        SuccessToaster(response.data.message);
      } else {
        ErrorToaster(response.data.message);
      }
      setLoader(false);
    })
    .catch((error) => {
      ErrorToaster("Something Went Wrong");
      setLoader(false);
    });
};

export const removeCartApi = (id, token, setLoader) => {
  setLoader(true);
  let data = "";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseURL}remove-cart-item/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      setLoader(false);
    })
    .catch((error) => {
      console.log(error);
      setLoader(false);
    });
};

export const FetchFaqApi =(formdata, callback)=>{
  let data = JSON.stringify(formdata);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-faq-list`,
    headers: { 
      'Content-Type': 'application/json', 
      },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    // console.log(JSON.stringify(response.data));
    callback(response.data.data.faq_list)
  })
  .catch((error) => {
    console.log(error);
  });
}

export const addNewsLetterApi =(email,setEmail)=>{
  let data = JSON.stringify({
    "email": email
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}add-to-newsletter`,
    headers: { 
      'Content-Type': 'application/json', 
      },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
   if(response.data.status==='failed'){
      ErrorToaster(response.data.message)
   } else{
    SuccessToaster(response.data.message)
    setEmail('')
   }
  })
  .catch((error) => {
    console.log(error);
    ErrorToaster('Something Went Wrong')
  });
}

export const CreateOrderApi =(token, formdata,setOrderNumber, callback)=>{
  let data = JSON.stringify(formdata);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}create-order`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}` },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(response.data, 'created success 0000000000000');
    setOrderNumber(response.data.order_number)
    callback(response.data.order_number)

  })
  .catch((error) => {
    console.log(error);
  });
}

 export const PaymentSuccessApi =(userToken, orderNo,id, navigation)=>{
  let data = JSON.stringify({
    "order_number": orderNo,
    "cart_id":id
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}payment-authorization`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${userToken}`
     },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
  if(response.data.status==='failed'){
    ErrorToaster(response.data.message)
  } else{
    SuccessToaster(response.data.message)
    navigation('/')
  }
   
  })
  .catch((error) => {
    console.log(error);
  });
}

export const FetchAllOrderApi=(token, formdata,setLoader, callback)=>{
  setLoader(true)
  let data = JSON.stringify(formdata);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}fetch-all-placed-order-list`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}` },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    setLoader(false)
    console.log(JSON.stringify(response.data));
    callback(response.data)
  })
  .catch((error) => {
    setLoader(false)
    console.log(error);
  });
}

export const CancelOrderApi=(token,formdata, setupdatePage)=>{
  setupdatePage(true)
  let data = JSON.stringify(formdata);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}cancel-order`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`},
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    setupdatePage(false)
    if(response.data.status==='failed'){
      ErrorToaster(response.data.message)
    } else{
      SuccessToaster(response.data.message)
    }
    
  })
  .catch((error) => {
    console.log(error);
    setupdatePage(false)
  });
}

export const ContactUsApi=(formdata,  setEmail, setPhone, setName, setMessage)=>{
  let data = JSON.stringify(formdata);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}contact-us`,
    headers: { 
      'Content-Type': 'application/json', },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    if(response.data.status==='failed'){
      ErrorToaster(response.data.message)
    } else{
      setEmail('')
       setPhone('') 
       setName('') 
       setMessage('')
      SuccessToaster(response.data.message)
    }
  })
  .catch((error) => {
    console.log(error);
  });
  
}

export const ChnagePasswordApi=(token , formdata,  setoldPassword,setNPassword, setPassword)=>{
  let data = JSON.stringify(formdata);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}password-change`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    if(response.data.status==='failed'){
      ErrorToaster(response.data.message)
    } else{
      setoldPassword('')
      setNPassword('') 
      setPassword('') 
      SuccessToaster(response.data.message)
      
    }
    
  })
  .catch((error) => {
    console.log(error);
  });
  
}

export const DeleteAccountApi=(token,navigation, setStoreUserTokenRedux)=>{
  let data = JSON.stringify();
  
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseURL}delete-account`,
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    if(response.data.status==='failed'){
      ErrorToaster(response.data.message)
    } else{
      setStoreUserTokenRedux(null)
      localStorage.removeItem('SAVMACHINETOKEN')
      SuccessToaster(response.data.message)
      navigation('/')
    }
    
  })
  .catch((error) => {
    console.log(error);
  });
  
}

export const searchApi =(lang,keyword, callback)=>{
  let data = JSON.stringify({
    "lang":lang,
    "service_name":keyword
});
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}category-search`,
    headers: { 
      'Content-Type': 'application/json', 
      },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
   if(response.data.status==='failed'){
    callback([])
   } else{
    callback(response.data.data.services)
   }
  })
  .catch((error) => {
    console.log(error);
    callback([])
    // ErrorToaster('Something Went Wrong')
  });
}
import React,{useState, useEffect} from 'react'
import Cart from './Cart'
import { connect } from 'react-redux';
import {  fetchCartApi, removeCartApi, updateCartApi} from "../AllAPIS";
import CheckoutHeader from '../Sections/CheckoutHeader';
import BookingDetails from '../Layouts/BookingDetails';
import GetLocation from '../Layouts/GetLocation';
import './../Styles/viewcart.css'

function ViewCart({getSelectedLanguage}) {

    const [page,setPage] = useState(1)
    const [cartData, setCartData] = useState([])
    const [loader, setLoader] = useState(false)
    const [cartLoader, setCartLoader] = useState(false)
    const [removeCartAfterCreateOrder, setRemoveCartAfterCreateOrder] = useState(false)


  useEffect(()=>{
    var tokenSav = localStorage.getItem('SAVMACHINETOKEN')
    fetchCartApi(tokenSav,getSelectedLanguage, page, (data)=>{
      setCartData(data)
    })
  },[loader,cartLoader])

  const FetchCartOnBuyButton=()=>{
    // var tokenSav = localStorage.getItem('SAVMACHINETOKEN')
    // fetchCartApi(tokenSav,getSelectedLanguage, page, (data)=>{
    //   setCartData(data)
    // })
    setRemoveCartAfterCreateOrder(true)
  }

  const handleRemoveCart=(id)=>{
    var tokenSav = localStorage.getItem('SAVMACHINETOKEN')
    removeCartApi(id, tokenSav, setCartLoader)
  }
  const handlUpdateCart = (quantity, cartId) => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    updateCartApi(tokenSav, quantity, cartId, setCartLoader);
  };

  return (
    <div>
        <CheckoutHeader />
       <div className="container d-none d-sm-block mt-3">
          <div className="row">
            <div className="col-1"></div>
             <div className="col-6">
                <BookingDetails />
                <GetLocation cartData={cartData} FetchCartOnBuyButton={FetchCartOnBuyButton} />
             </div>

             <div className="col-4">
             <Cart removeCartAfterCreateOrder={removeCartAfterCreateOrder} cartData={cartData} handleRemoveCart={handleRemoveCart} setCartLoader={setCartLoader} cartLoader={cartLoader} handlUpdateCart={handlUpdateCart} />
             </div>
             <div className="col-1"></div>
          </div>
       </div>

       {/* ///// FOR MOBILE VIEW */}
       <div className="container d-sm-none d-block mt-3">
          <div className="column">
             <div className="col-12">
                <BookingDetails />
                <GetLocation cartData={cartData} FetchCartOnBuyButton={FetchCartOnBuyButton} />
             </div>

             <div className="col-12">
             <Cart removeCartAfterCreateOrder={removeCartAfterCreateOrder} cartData={cartData} handleRemoveCart={handleRemoveCart} setCartLoader={setCartLoader} cartLoader={cartLoader} handlUpdateCart={handlUpdateCart} />
             </div>
          </div>
       </div>

    </div>
  )
}

const mapStateToProps = (state) => {
    return {
      getStoreUserToken: state.storeUserToken.token,
      getUserCordinatesRedux:state.userCordinates,
      getSelectedLanguage: state.language.language,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {};
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ViewCart);
  
  

  

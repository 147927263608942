import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "./../Styles/services.css";

function Services({ getStoreUserToken, allServices }) {
  const navigation = useNavigate();

  return (
    <div className="container py-5">
      <div className="row">
        {allServices?.map((ele, i) => {
          return (
            <div key={i} className="col-md-3 col-xxl-3 justify-content-center">
              <div
                className="services"
                onClick={() => navigation(`/service/${ele.slug}/${ele.id}`)}
              >
                <img src={ele.image} alt="products-image" />
                <h6 className="text-center py-2 pixel-15 mt-3">{ele.name}</h6>
                <button>View More</button>
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);

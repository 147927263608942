import React, { useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "./../Styles/services.css";
import { fetchServicesListApi } from "../AllAPIS";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";

function ServicesComponent({ getSelectedLanguage ,getAllServicesRedux}) {
  const navigation = useNavigate();
  const [allServices, setAllServices] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);

  useLayoutEffect(() => {
    fetchServicesList();
  }, [getSelectedLanguage]);

  const fetchServicesList = () => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    // fetchServicesListApi(page,setLoader, tokenSav, getSelectedLanguage, (data) => {
    //   setAllServices(data);
    // });
  };

  return (
    <>
      <Navbar allServices={allServices} />
      {loader ? (
        <div className="loader-main">
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="container py-5">
          <div className="row">
            <h4 className="services_component_heading">All Services</h4>
            {getAllServicesRedux?.map((ele, i) => {
              return (
                <div key={i} className="col-md-6 col-xxl-6">
                  <div className="services_component shadow">
                    <img className="" src={ele.image} alt="products-image" />
                    <div className="services_component_text">
                      <h6 className="y-2 pixel-13 px-">{ele.name}</h6>
                      <p>{ele.description.slice(0, 130)}...</p>
                      <h5>Services Available :-</h5>
                      <div className="d-flex">
                        {ele?.type_of_service?.map((val, i) => {
                          return <h4 key={i}>{val?.service_type_name}</h4>;
                        })}
                      </div>
                      <button
                        onClick={() =>
                          navigation(`/service/${ele.slug}/${ele.id}`)
                        }
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getSelectedLanguage: state.language.language,
    getAllServicesRedux:state.services.services
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesComponent);

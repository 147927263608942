import { UPDATE_PAGE} from "../type"

//initializing state 
const initialState = {
    data: false,
}

const setUpdatePageGlobalReducer= (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PAGE: return {
            ...state,
            data: action.payload,
        }
        default: return state
    }
}

export default setUpdatePageGlobalReducer;
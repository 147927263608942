import React from 'react'
import './../Styles/privacy.css'
import Navbar from '../Sections/Navbar'
import Footer from '../Sections/Footer'
import { connect } from 'react-redux'
import { useLayoutEffect } from 'react'

function Privacy({getSelectedLanguage}) {

    useLayoutEffect(()=>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    },[])
  return (
    <div>
        <Navbar />

        <div className="container my-5 py-3">
          <h2 className='pb-4 fw-bold '>Privacy Policy</h2>
           {
            getSelectedLanguage==='en'
            ?
            <div className='privacy_main' >
                <h4>Collection and use of personal data</h4>
                <p>We would like to draw your attention to the fact that we will collect and use the personal data you provide to us because this is necessary to conclude with you and perform a possible agreement. This applies to both our (potential) customers and to parties from which we purchase goods and/or services.  </p>

                <p>If you are a (potential) customer of ours, we will use your data to be able to send you an offer, to be able to determine what specifications or wishes certain goods or services must satisfy, in order to be able to deliver goods or carry out the activities for you, in order to be able to invoice you and to be able to communicate with you quickly and efficiently concerning the implementation aspects of the agreement. </p>

                <p>If you are a (potential) supplier or another contractor, your personal data are also required for concluding and performing the agreement. In case of purchasing, this is necessary in order to be able to inform you what specifications or wishes certain goods or services should satisfy in our opinion, to be able to send a request for an offer or to place an order with you, to be able to pay your invoices and to able to communicate with you quickly and efficiently concerning other aspects of the agreement.</p>

                <p>You are not obliged to provide your personal data to us. If you do not provide personal data to us or provide us with insufficient personal data it may be the case that we will not be able to carry out the abovementioned activities. </p>

                <h4>Direct marketing</h4>

                <p>If you place regular orders with us, we will store and use the personal data you have provided in order to be able to inform you personally by e-mail in the future of our existing and new products and services and possibly make you an offer in this connection. We have a legitimate interest in using your personal data for this purpose, namely selling our products and services. Every time we send you a marketing e-mail, you have the option of informing us that you no longer wish to receive such e-mails. See in this connection the opt-out link at the bottom of each mailing. If you are a one-off client of ours we will only send you marketing e-mails if you have granted us your approval to do so in advance. </p>

                <h4>Retention period personal</h4>

                <p>If you requested an offer from us, but you did not become a customer of ours, we will remove your data at most one year after our last contact. We will also remove your personal data at most one year after our last contact if we received an offer from you but we did not become your customer. If you did become our customer or we did become your customer, we will retain your personal data for a term of seven years after the end of the financial year in which the agreement with you was performed. The period of seven years corresponds to the period for which we are obliged to keep our records for the Tax and Customs Administration. We will remove your personal data after this period has ended. </p>

                <h4>Cookies</h4>

                <p>Cookies are small text files that are placed on your computer, tablet or smartphone during the visit to our website. Information is stored in these text files that is later recognised again by the website during a subsequent visit.</p>

                <p>Our website uses tracking. We do so to collect information about your internet behaviour so that we can present you with targeted offers of products or services. You have the right to withdraw your consent at any time. Your data are stored for at most one year. We also place functional cookies. We do so in order to make it easy to use our website. This includes matters such as keeping products in your shopping cart or remembering your login details during your visit.</p>

                <p>Analytical cookies allow us to see what pages are visited and what sections of our website receive clicks. We use Google Analytics for this purpose. The information collected by Google in this way is anonymised as much as possible.</p>

                <h4>Transfer to third parties</h4>

                <p>In connection with the performance of a possible agreement with you it may be necessary to provide your personal data to parties that supply parts, materials or products to us or who perform activities on our instructions. We furthermore make use of external server space for the storage of (parts of) our sales and purchasing records and our records of business relations. Your personal data are part of these records. Therefore your personal data are provided to the service space provider. We also use Microsoft Office and the related storage facilities for e-mails and other files. As we make use of a newsletter mailing service, your personal data are finally transferred to the provider of this service.</p>

                <h4>Your rights</h4>

                <p>You have the right to request to inspect your own personal data. If there is reason to do so, you may also request us to supplement your personal data or to correct inaccuracies. You also have the right to request that your personal data be deleted or that the use of your personal data be limited. You can also submit an objection to the collection and use of your personal data to us or submit a complaint to the Dutch Data Protection Authority. And finally, you have the right to request to obtain your personal data or that they are transferred to another party. In order to be able to exercise your rights, you can apply to: </p>



            </div>
            :
            
            <div className='privacy_main' >
                <h4>Collecte et utilisation des données à caractère personnel des clients, des fournisseurs et des autres sous-traitants</h4>
                <p>Nous attirons votre attention sur le fait que nous recueillerons et utiliserons les données à caractère personnel que vous nous fournissez, car ces opérations sont nécessaires pour conclure et exécuter un éventuel accord avec vous. Cela s'applique tant à nos clients (potentiels) qu'aux parties auprès desquelles nous achetons des biens et/ou des services.  </p>

                <p>Si vous comptez parmi nos clients (potentiels), nous utilisons vos données pour pouvoir vous envoyer un devis, pour déterminer les spécifications ou les exigences qu'un bien ou un service particulier doit respecter, pour pouvoir livrer des marchandises ou effectuer des travaux, pour pouvoir vous envoyer nos factures et communiquer facilement et avec efficacité sur les modalités d'exécution du contrat. </p>

                <p>Si vous êtes un fournisseur (potentiel) ou un autre sous-traitant, vos données à caractère personnel sont également nécessaires à la conclusion et à l'exécution du contrat. Cela s'avère nécessaire en cas d'achat pour pouvoir vous indiquer nos spécifications ou nos exigences qu'un bien ou un service particulier doit respecter, pour envoyer une demande de devis ou pour passer commande chez vous, pour payer vos factures et communiquer facilement et efficacement avec vous sur d'autres aspects du contrat.</p>

                <p>Vous n'êtes pas obligé de nous transmettre vos données à caractère personnel. Toutefois, si vous ne nous fournissez pas ou pas suffisamment de données à caractère personnel, il est possible que nous ne soyons pas en mesure de mener à bien les activités susmentionnées. </p>
                
                <h4>Marketing direct</h4>
                <p>Si vous commandez régulièrement chez nous, nous conserverons les données à caractère personnel que vous nous avez fournies et les utiliserons pour vous informer personnellement par courrier électronique de nos produits et services existants et de nos nouveaux produits et services et, éventuellement, pour vous faire une offre. Nous disposons d'un intérêt légitime pour utiliser vos données à caractère personnel à cette fin, à savoir la commercialisation de nos produits et services. Chaque fois que nous vous envoyons un courrier électronique publicitaire, vous avez la possibilité de nous faire savoir que vous ne souhaitez plus en recevoir. Pour ce faire, veuillez suivre le lien de désabonnement au bas de chaque courrier électronique. Si vous êtes un client occasionnel chez nous, nous ne vous enverrons des messages publicitaires que si vous nous avez donné votre consentement préalable. </p>

                <h4>Période de conservation des données à caractère personnel des clients, fournisseurs et autres sous-traitants</h4>

                <p>Si vous nous avez demandé un devis, mais que vous n'êtes pas devenu notre client, nous effacerons vos données au plus tard un an après notre dernier contact. De même, si nous avons reçu une offre de votre part, mais que nous ne sommes pas devenus client chez vous, vos données à caractère personnel seront effacées au plus tard un an après notre dernier contact. En revanche, si vous êtes devenu client chez nous ou si nous sommes devenus client chez vous, nous conserverons vos données à caractère personnel pendant une période de sept années après la fin de l'exercice comptable au cours duquel le contrat avec vous a été complètement exécuté. La période de sept ans correspond à la période pendant laquelle nous sommes tenus de conserver notre administration pour le fisc. Une fois cette période arrivée à échéance, nous supprimerons vos données à caractère personnel. </p>

                <h4>Cookies</h4>

                <p>Les cookies sont de petits fichiers de texte placés sur votre ordinateur, votre tablette ou votre smartphone lorsque vous consultez notre site Web. Des informations sont enregistrées dans ces fichiers de texte ; elles peuvent être reconnues par le site Web lors d'une visite ultérieure.</p>

                <p>Notre site Web utilise des cookies de suivi si vous les avez autorisés. Nous les utilisons dans le but de recueillir des informations sur votre comportement en ligne afin de pouvoir émettre des offres ciblées pour des produits ou des services. Vous pouvez en tout temps retirer votre consentement. Vos données sont conservées pendant un an au maximum.</p>

                <p>Nous plaçons également des cookies fonctionnels en vue de faciliter l'utilisation de notre site Web. Pensez notamment à l'enregistrement des produits dans votre panier d'achats ou l'enregistrement de vos données de connexion lors de votre visite.</p>

                <p>Les cookies analytiques nous permettent de voir quelles pages sont consultées et où vous cliquez sur notre site Internet. Pour ce faire, nous avons recours à Google Analytics. Les informations collectées de la sorte par Google sont anonymisées dans la mesure du possible.</p>

                <h4>Transmission à des tiers</h4>
                <p>pDans le cadre de l'exécution d'un accord éventuel avec vous, nous pouvons être amenés à devoir fournir vos données à caractère personnel à des parties qui nous fournissent des pièces, du matériel et des produits ou qui effectuent des activités pour notre compte. Nous utilisons également un espace serveur externe pour le stockage de (certaines parties de) notre administration des ventes et des achats et de notre fichier de relations commerciales, dont font partie vos données à caractère personnel. Par conséquent, vos données à caractère personnel sont transmises à notre fournisseur d'espace de serveurs. Nous utilisons également Microsoft Office et les options de stockage associées pour les e-mails et autres fichiers. Enfin, étant donné que nous avons recours à un service d'envoi de bulletins d'informations, vos données à caractère personnel sont transmises au fournisseur de ce service.</p> 

                <h4>Vos droits</h4>

                <p>Vous avez le droit de nous demander d'accéder à vos données à caractère personnel. S'il y a lieu de le faire, vous pouvez également nous demander de compléter vos données à caractère personnel ou de corriger toute inexactitude. En outre, vous avez le droit de demander l'effacement de vos données à caractère personnel ou de restreindre leur utilisation. Vous pouvez également vous opposer à la collecte et à l'utilisation de vos données ou déposer une plainte auprès de l'Autorité en charge des données à caractère personnel. Enfin, vous pouvez nous demander d'obtenir vos données à caractère personnel ou de les transférer à un tiers. Pour exercer vos droits, vous pouvez vous adresser à : </p>


            </div>
           }
        </div>

      <Footer />
    </div>
  )
}


const mapStateToProps = (state) => {
    return {
      getSelectedLanguage: state.language.language,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
 
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
  
  


import React, { useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./../Styles/cart.css";

function Cart({ removeCartAfterCreateOrder, cartData, handleRemoveCart , setCartLoader, cartLoader, handlUpdateCart}) {
  const navigation =useNavigate()
  let path= window.location.pathname.split("/")[1];
  const [cartIdLoader, setCartIdLoader] = useState(false);

  let totalCartPrice = 0;

  for (let i = 0; i < cartData.length; i++) {
    totalCartPrice += cartData[i]?.regular_price*cartData[i]?.quantity;
  }
  // console.log(cartData,'cartDatasdsafwdtwrt')

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="main-card">
              <div className="px-3">
                <h5>Your Card</h5>
              </div>

              {cartData?.map((item, i) => {
                return (
                  <div key={i}>
                    <div className="row d-flex align-items-center px-4 py-2">
                      <div className="col-md-6 p-0 m-0 ">
                        <p className="combo">{item?.service_package_name}</p>
                      </div>
                      <div className="col-md-3 d-flex justify-content-start p-0">
                        <div className="plus-minus d-flex justify-content-around align-items-center">
                       {
                        removeCartAfterCreateOrder===true?
                        <p>{item?.quantity}</p>
                        :
                        <>
                        <AiOutlineMinus
                          onClick={() =>{
                            if(item?.quantity===1 || item?.quantity==='1'){
                              handleRemoveCart(item?.cart_id)
                            } else{
                              setCartIdLoader( item?.cart_id)
                            handlUpdateCart(
                              parseInt(item?.quantity) - 1,
                              item?.cart_id
                            )}
                          } }
                        />
                        <p>{item?.quantity}</p>
                        <AiOutlinePlus
                          onClick={() =>{
                            setCartIdLoader( item?.cart_id)
                            handlUpdateCart(
                              parseInt(item?.quantity) + 1,
                              item?.cart_id
                            )}
                          }
                        />
                      </>
                       }
                          
                    
                        </div>
                      </div>
                      <div className="col-md-3 p-0 m-0  d-flex justify-content-sm-end justify-content-start">
                        <div className="discount">
                          <p className="text-dark ">$ {item?.regular_price * item?.quantity}</p>
                          <span>( $ {item?.base_price * item?.quantity })</span>
                        </div>
                      </div>
                    </div>
                    <div className=" Congratulations mt-2">
                      <p className="text-center p-1">
                        Congratulations! ${item?.base_price * item?.quantity - item?.regular_price * item?.quantity} saved so far!
                      </p>
                    </div>
                  </div>
                );
              })}
              <div onClick={()=>{ navigation('/coffee-machine-a-cafe/cart-view') }} className="container-fluid pb-2 view_cart_button">
                {
                  path==='cart-view'
                  ?
                  <div className="d-flex justify-content-between align-items-center  btn-content ">
                     <div>
                    <p className="text-white ">Total Amount</p>
                  </div>
                  <div className="d-flex ">
                    <p className="text-white ">$ {totalCartPrice}</p>
                  </div>
                 
                </div>
                :
                  <div className="d-flex justify-content-between align-items-center  btn-content ">
                  <div className="d-flex ">
                    <p className="text-white ">$ {totalCartPrice}</p>
                  </div>
                  <div>
                    <p className="text-white ">View Cart</p>
                  </div>
                </div>
                }
              
              </div>
            </div>

         
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;

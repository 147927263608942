import { USER_CORDINATES} from "../type"

//initializing state 
const initialState = {
    lat: null,
    lng:null
}

const setUserCordinatsReducer= (state = initialState, action) => {
    switch (action.type) {
        case USER_CORDINATES: return {
            ...state,
            lat: action.payload.lat,
            lng: action.payload.lng,
        }
        default: return state
    }
}

export default setUserCordinatsReducer;
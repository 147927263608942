import React, { useState, useEffect, useLayoutEffect } from "react";
import { AiFillSetting } from "react-icons/ai";
import { MdOutlineMobileFriendly } from "react-icons/md";
import { PiStarOfDavidFill } from "react-icons/pi";
import { FiArrowRight } from "react-icons/fi";
import { AiTwotonePhone } from "react-icons/ai";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import AllPackages from "./AllPackages";
import Cart from "../Component/Cart";
import LoginModal from "../Modals/LoginModal";
import { connect } from "react-redux";
import {
  fetchPackagesListApi,
  fetchServiceSingleApi,
  addToCartApi,
  fetchCartApi,
  removeCartApi,
  updateCartApi,
  FetchServicDetailsApi,
} from "../AllAPIS";
import "./../Styles/servicepage.css";
import { useTranslation } from "react-i18next";
import { setUserCordinates } from "../Redux";

function ServicesPage({
  getUserCordinatesRedux,
  getSelectedLanguage,
  getUpdatePageGlobalRedux,
  setUserCordinatesRedux,
}) {
  const { t } = useTranslation();
  let serviceId = window.location.pathname.split("/")[3];
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [packages, setPackages] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [cartLoader, setCartLoader] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [storeCartId, setStoreCartId] = useState("");
  const [selectedPackage, setSelectedPackage] = useState();

  useEffect(() => {

      fetchDataFunction();
    
  }, [getSelectedLanguage, getUserCordinatesRedux?.lat]);

  const fetchDataFunction = () => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    fetchServiceSingleApi(
      serviceId,
      getSelectedLanguage,
      tokenSav,
      setSpinner,
      (data) => {
        setData(data[0]);
        setSelectedPackage(data[0]?.type_of_service[0]?.service_type_name);
      }
    );

    if (getUserCordinatesRedux?.lat ) {
      fetchPackagesListApi(
        serviceId,
        getUserCordinatesRedux?.lat,
        getUserCordinatesRedux?.lng,
        page,
        getSelectedLanguage,
        (data) => {
          setPackages(data);
        }
      );
    }
  
  };

  const addToCartHandle = (id) => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    if (tokenSav === null || tokenSav === undefined) {
      setStoreCartId(id);
      setShowLoginModal(true);
    } else {
      addToCartApi(id, tokenSav, setLoader);
    }
  };

  useEffect(() => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    fetchCartApi(tokenSav, getSelectedLanguage, 1, (data) => {
      setCartData(data);
    });
  }, [loader, cartLoader, getSelectedLanguage]);

  const handleRemoveCart = (id) => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    removeCartApi(id, tokenSav, setCartLoader);
  };
  const handlUpdateCart = (quantity, cartId) => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    updateCartApi(tokenSav, quantity, cartId, setCartLoader);
  };

  const handleFilterPackage = (packageName) => {
    setSelectedPackage(packageName);
  };

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    var crd = pos.coords;
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    setUserCordinatesRedux({ lat: crd.latitude, lng: crd.longitude });
    fetchDataFunction();
  }

  function errors(err) {
    console.warn(`ERROR(---${err.code}): ${err.message} trueeeeeeeeeeee`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
          }
          result.onchange = function () {};
        });
    } else {
      alert("Sorry Not available!");
    }
  }, []);

  console.log(
    getUserCordinatesRedux,
    "getUserCordinatesReduxgetUserCordinatesReduxgetUserCordinatesRedux"
  );
  return (
    <>
      {spinner ? (
        <div className="loader-main">
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <>
          <LoginModal
            setShowLoginModal={setShowLoginModal}
            showLoginModal={showLoginModal}
            storeCartId={storeCartId}
          />
          <Navbar />
          <div className="container-fluid p-0 m-0">
            <div className="row">
              <div className="col-md-12 m-0 p-0">
                <div
                  className="service-banner"
                  style={{ backgroundImage: `url(${data?.banner_image})` }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12  col-sm-6">
                        <h2>{data?.name}</h2>
                        <h3>{data?.description}</h3>
                        <div className="d-flex mt-4">
                          <button>
                            <AiTwotonePhone />
                            {t("Contact Us")}
                          </button>
                          {/* <h4 className="mx-4">Know More</h4> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-6"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="select-service">
              <h2 className="mb-3">{t("Select a services")}</h2>
              <div className="row">
                {data?.type_of_service?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`col-4 col-md-3 selected_services  mx-sm-3 py-4 d-flex justify-content-center text-center p-0 ${
                        selectedPackage === item.service_type_name
                          ? "selected_services_selected"
                          : ""
                      }`}
                      onClick={() => {
                        handleFilterPackage(item.service_type_name);
                      }}
                    >
                      <div className="d-flex flex-column align-items-center">
                        <img src={item.service_type_image} alt="image" />
                        <span>{item.service_type_name}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <AllPackages
                  packages={packages}
                  addToCartHandle={addToCartHandle}
                  loader={loader}
                  selectedPackage={selectedPackage}
                  getUserCordinatesRedux={getUserCordinatesRedux}
                />
              </div>
              <div className="col-md-5">
                {cartData?.length === 0 ? (
                  <div className="empty_cart">
                    <img src="/img/Cart.png" alt="cart" />
                    <p>{t("No items in your cart")}</p>
                  </div>
                ) : (
                  <Cart
                    cartData={cartData}
                    handleRemoveCart={handleRemoveCart}
                    setCartLoader={setCartLoader}
                    cartLoader={cartLoader}
                    handlUpdateCart={handlUpdateCart}
                  />
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getUserCordinatesRedux: state.userCordinates,
    getSelectedLanguage: state.language.language,
    getUpdatePageGlobalRedux: state.updatePageGlobal.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserCordinatesRedux: (parameter) => {
      dispatch(setUserCordinates(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage);

import React, { useEffect, useLayoutEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
import { connect } from "react-redux";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { setStoreUserToken, setSelectedLanguages } from "../Redux";
import { logoutApi, fetchServicesListApi, fetchUserApi } from "../AllAPIS";
import ProfileModal from "../Modals/ProfileModal";
import "../Styles/navbar.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { setUpdatePageGlobal } from "../Redux";

var token = localStorage.getItem("SAVMACHINETOKEN");

function Navbar({
  getStoreUserToken,
  setStoreUserTokenRedux,
  setSelectedLanguagesRedux,
  getSelectedLanguage,
  setUpdatePageGlobalRedux,
  getUpdatePageGlobalRedux,
  getAllServicesRedux
}) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [updateNavbarPage, setUpdateNavbarPage] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [showProfileModal, setShowProfileModal] = useState(false);

  const [page, setPage] = useState(1);
  const [allServices, setAllServices] = useState([]);
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);

  const logoutHandle = () => {
    logoutApi(getStoreUserToken, navigation, setStoreUserTokenRedux);
    setUpdateNavbarPage(!updateNavbarPage);
  };

  useLayoutEffect(() => {
    fetchServicesList();
  }, [getSelectedLanguage, updateNavbarPage]);

  const fetchServicesList = () => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    // fetchServicesListApi(page,setLoader, tokenSav, getSelectedLanguage, (data) => {
    //   setAllServices(data);
    // });
    var token = localStorage.getItem("SAVMACHINETOKEN");
    if(token){
      fetchUserApi(token, (data) => {
        setUserData(data);
      });
    }
  };
  // console.log(getAllServicesRedux,'getAllServicesRedux')

  return (
    <>
      <ProfileModal
        setShowProfileModal={setShowProfileModal}
        showProfileModal={showProfileModal}
        userData={userData}
        setLoader={setLoader}
      />
      <div className=" shadow">
        <div className="container ">
          <div className="row d-none d-sm-flex">
            <div className="col-xxl-2 col-md-2">
              <div onClick={() => navigation(`/`)} className="logo">
                <img src="/img/logo.png" alt="image" />
              </div>
            </div>
            <div className="col-2 d-none d-xxl-none"></div>

            <div className="col-xxl-10 col-md-10 d-flex justify-content-end align-items-center">
              <div className="navbar-list ">
                <ul className="p-0 m-0 d-flex justify-content-center align-items-center">
                  <div className="navbar_dropdown_Services">
                    <li
                      className="list-group-item px-4 py-2 mx-2"
                      role="button"
                    >
                      {t("Services")} <AiFillCaretDown />
                    </li>
                    <div className="navbar_dropdown-content_Services">
                      {getAllServicesRedux?.map((item, i) => {
                        return (
                          <a
                            key={i}
                            onClick={() => {
                              setUpdatePageGlobalRedux(
                                !getUpdatePageGlobalRedux
                              );
                              navigation(`/service/${item?.slug}/${item.id}`);
                            }}
                          >
                            {item?.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>

                  <li
                    onClick={(e) => navigation("/signup-technician")}
                    className="list-group-item px-4 py-2 mx-2"
                    role="button"
                  >
                    {t("Register As a Technician")}
                  </li>

                  <div className="navbar_dropdown_lang">
                    <li
                      className="list-group-item px-4 py-2 mx-2"
                      role="button"
                    >
                      {getSelectedLanguage === "fr" ? (
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/299/299753.png"
                          alt="fr"
                        />
                      ) : (
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/299/299722.png"
                          alt="fr"
                        />
                      )}
                      {getSelectedLanguage === "fr" ? "French" : "English"}
                    </li>
                    <div className="navbar_dropdown_content_lang">
                      <div className="lang_dropdown">
                        <a
                          onClick={() => {
                            localStorage.setItem("SAVMACHINELANG", "en");
                            setSelectedLanguage("English");
                            setSelectedLanguagesRedux("en");
                            i18next.changeLanguage("en");
                          }}
                          className="list-group-item"
                          role="button"
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/299/299722.png"
                            alt="en"
                          />
                          English
                        </a>
                        <a
                          onClick={() => {
                            localStorage.setItem("SAVMACHINELANG", "fr");
                            setSelectedLanguage("French");
                            setSelectedLanguagesRedux("fr");
                            i18next.changeLanguage("fr");
                          }}
                          className="list-group-item"
                          role="button"
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/299/299753.png"
                            alt="fr"
                          />
                          French
                        </a>
                      </div>
                    </div>
                  </div>

                  {getStoreUserToken ? (
                    <div className="navbar_dropdown_Profile">
                      <li
                        className="list-group-item d-flex justify-content-center align-items-center px-4 py-2 mx-2"
                        role="button"
                        onClick={() => {}}
                      >
                        <FaRegUser className="mx-2" /> {t("Profile")}
                      </li>
                      <div className="navbar_dropdown-content_Profile">
                        <div className="profile_dropdown">
                          <a
                            onClick={() => {
                              navigation("/");
                              setShowProfileModal(true);
                            }}
                          >
                            {" "}
                            {userData?.name}
                            <img src={userData?.image} alt="img" />
                          </a>
                          <a onClick={() => navigation("/coffee-machine-a-cafe/booking/details")}>
                            {t("MyOrders")}
                          </a>
                          <a onClick={() => logoutHandle()}>
                            {t("Logout")} <AiOutlineLogout />
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <li
                      className="list-group-item px-4 py-2 mx-2"
                      role="button"
                      onClick={() => navigation("/login")}
                    >
                      {t("Login")}
                      {"/"}
                      {t("Signup")}
                    </li>
                  )}

                  {profileDropdown ? (
                    <div className="profile_dropdown">
                      <p
                        onMouseEnter={() => setProfileDropdown(true)}
                        onMouseLeave={() => setProfileDropdown(false)}
                        onClick={() => {
                          navigation("/");
                          setShowProfileModal(true);
                        }}
                      >
                        <img src={userData?.image} alt="img" />
                        {userData?.name}
                      </p>
                      <p
                        onMouseEnter={() => setProfileDropdown(true)}
                        onMouseLeave={() => setProfileDropdown(false)}
                        onClick={() => logoutHandle()}
                      >
                        Logout <AiOutlineLogout />
                      </p>
                    </div>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>

          <div className="row d-flex d-sm-none py-2 border-bottom">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <div onClick={() => navigation(`/`)} className="logo">
                <img src="/img/logo.png" alt="image" />
              </div>
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <div className="navbar_dropdown_lang">
                <li className="list-group-item px-4 py-2 mx-2" role="button">
                  {getSelectedLanguage === "fr" ? (
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/299/299753.png"
                      alt="fr"
                    />
                  ) : (
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/299/299722.png"
                      alt="fr"
                    />
                  )}
                  {getSelectedLanguage === "fr" ? "French" : "English"}
                </li>
                <div className="navbar_dropdown_content_lang">
                  <div className="lang_dropdown">
                    <a
                      onClick={() => {
                        localStorage.setItem("SAVMACHINELANG", "en");
                        setSelectedLanguage("English");
                        setSelectedLanguagesRedux("en");
                        i18next.changeLanguage("en");
                      }}
                      className="list-group-item"
                      role="button"
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/299/299722.png"
                        alt="en"
                      />
                      English
                    </a>
                    <a
                      onClick={() => {
                        localStorage.setItem("SAVMACHINELANG", "fr");
                        setSelectedLanguage("French");
                        setSelectedLanguagesRedux("fr");
                        i18next.changeLanguage("fr");
                      }}
                      className="list-group-item"
                      role="button"
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/299/299753.png"
                        alt="fr"
                      />
                      French
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 d-flex d-sm-none justify-content-center align-items-center">
            <div className="navbar-list ">
              <ul className="p-0 m-0 d-flex justify-content-center align-items-center">
                <div className="navbar_dropdown_Services py-3">
                  <li className="list-group-item px-4 py-2 mx-2" role="button">
                    {t("Services")} <AiFillCaretDown />
                  </li>
                  <div className="navbar_dropdown-content_Services">
                    {getAllServicesRedux?.map((item, i) => {
                      return (
                        <a
                          key={i}
                          onClick={() => {
                            setUpdatePageGlobalRedux(!getUpdatePageGlobalRedux);
                            navigation(`/service/${item?.slug}/${item.id}`);
                          }}
                        >
                          {item?.name}
                        </a>
                      );
                    })}
                  </div>
                </div>

                {getStoreUserToken ? (
                  <div className="navbar_dropdown_Profile">
                    <li
                      className="list-group-item d-flex justify-content-center align-items-center px-4 py-2 mx-2"
                      role="button"
                      onClick={() => {}}
                    >
                      <FaRegUser className="mx-2" /> {t("Profile")}
                    </li>
                    <div className="navbar_dropdown-content_Profile">
                      <div className="profile_dropdown">
                        <a
                          onClick={() => {
                            navigation("/");
                            setShowProfileModal(true);
                          }}
                        >
                          {" "}
                          {userData?.name}
                          <img src={userData?.image} alt="img" />
                        </a>
                        <a onClick={() => navigation("/coffee-machine-a-cafe/booking/details")}>
                          {t("MyOrders")}
                        </a>
                        <a onClick={() => logoutHandle()}>
                          {t("Logout")} <AiOutlineLogout />
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <li
                    className="list-group-item px-4 py-2 mx-2"
                    role="button"
                    onClick={() => navigation("/login")}
                  >
                    {t("Login")}
                    {"/"}
                    {t("Signup")}
                  </li>
                )}

                {profileDropdown ? (
                  <div className="profile_dropdown">
                    <p
                      onMouseEnter={() => setProfileDropdown(true)}
                      onMouseLeave={() => setProfileDropdown(false)}
                      onClick={() => {
                        navigation("/");
                        setShowProfileModal(true);
                      }}
                    >
                      <img src={userData?.image} alt="img" />
                      {userData?.name}
                    </p>
                    <p
                      onMouseEnter={() => setProfileDropdown(true)}
                      onMouseLeave={() => setProfileDropdown(false)}
                      onClick={() => logoutHandle()}
                    >
                      Logout <AiOutlineLogout />
                    </p>
                  </div>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getSelectedLanguage: state.language.language,
    getUpdatePageGlobalRedux: state.updatePageGlobal.data,
    getAllServicesRedux:state.services.services
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreUserTokenRedux: (parameter) => {
      dispatch(setStoreUserToken(parameter));
    },
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
    setUpdatePageGlobalRedux: (parameter) => {
      dispatch(setUpdatePageGlobal(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

import { STORE_TOKEN, USER_CORDINATES, SELECTED_LANGUAGE, ADDRESS, TIME_SLOT, UPDATE_PAGE, ALL_SERVICES} from "./type";


export const setStoreUserToken= (parameter)=>{
    return {
        type: STORE_TOKEN,
        payload:parameter
    }  
}

export const setUserCordinates= (parameter)=>{
    return {
        type: USER_CORDINATES,
        payload:parameter
    }  
}

export const setSelectedLanguages= (parameter)=>{
    return {
        type: SELECTED_LANGUAGE,
        payload:parameter
    }  
}
export const setAddress= (parameter)=>{
    return {
        type: ADDRESS,
        payload:parameter
    }  
}
export const setTimeSlot= (parameter)=>{
    return {
        type: TIME_SLOT,
        payload:parameter
    }  
}

export const setUpdatePageGlobal= (parameter)=>{
    return {
        type: UPDATE_PAGE,
        payload:parameter
    }  
}
export const setAllServiceAction= (parameter)=>{
    return {
        type: ALL_SERVICES,
        payload:parameter
    }  
}
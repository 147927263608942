import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import { setUserCordinates } from '../Redux';

function Error({setUserCordinatesRedux}) {
    const navigation =useNavigate()

    var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

    function success(pos) {
        var crd = pos.coords;
        console.log(`Latitude : ${crd.latitude}`);
        console.log(`Longitude: ${crd.longitude}`);
        setUserCordinatesRedux({lat:crd.latitude, lng:crd.longitude})
        navigation("/")
      }
      
      function errors(err) {
        if(err.code===1 || err.message==='User denied geolocation prompt'){
         navigation("/")
        }
        console.warn(`ERROR(---${err.code}): ${err.message} trueeeeeeeeeeee`);
      }

      const reloadPage =()=>{
        if (navigator.geolocation) {
            navigator.permissions
           .query({ name: "geolocation" })
           .then(function (result) {
             if (result.state === "granted") {
               console.log(' gante location')
               // console.log(result.state);
               //If granted then you can directly call your function here
               navigator.geolocation.getCurrentPosition(success);
             } else if (result.state === "prompt") {
               console.log(' location')
               navigator.geolocation.getCurrentPosition(success, errors, options);
             } else if (result.state === "denied") {
               //If denied then you have to show instructions to enable location
               console.log('cancel location')
             }
             result.onchange = function () {
               console.log(result.state,'000000000000000000000000');
             };
           });
       } else {
         alert("Sorry Not available!");
       }
      }

  return (
    <div className='d-flex justify-content-center align-items-center vh-100' >
      <div>
      <h4>You Have To Allow Your Current Location To Access Web Page </h4>
       <button onClick={()=>{reloadPage()}} className="btn-mobile-ios" >Go To Home Page</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setUserCordinatesRedux: (parameter) => {
        dispatch(setUserCordinates(parameter));
      },
    
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Error);


import React from "react";
import Navbar from "../Sections/Navbar";
import Footer from "../Sections/Footer";
import { useLayoutEffect } from "react";
import { connect } from "react-redux";

function Term({ getSelectedLanguage }) {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container my-5 py-3">
        <h2 className="pb-4 fw-bold ">Term & Conditions</h2>
        {getSelectedLanguage==="en" ? 
          <div>
            <p>
              These general conditions of sale (hereinafter the “CGV”) apply to
              sales of mechanical equipment machines, such as coffee machines,
              granita machines, Italian ice machines or even citrus presses.
              (hereinafter the “Products”) sold by the company SAV MACHINE A
              CAFE, a simplified joint stock company with capital of 500 euros,
              registered in the Paris Trade and Companies Register under number
              919 104 018, whose head office is located 60, rue François 1er,
              75008 Paris (hereinafter the “Company”) as well as the provision
              of installation, maintenance and repair services for the Products
              (hereinafter the “Services”) provided by the Company , to any
              professional client (hereinafter the “Client”).
            </p>

            <p>
              Entire General Terms and Conditions. The General Terms and
              Conditions take precedence over any other proposal, agreement or
              previous communication relating to the envisaged sales
              transaction.
            </p>

            <p>
              Divisibility. In the event that any provision hereof is deemed or
              declared void or unwritten, the other provisions will remain in
              full force and effect.
            </p>

            <h4>I. ENFORCEMENT</h4>

            <p>
              Opposable version of the General Terms and Conditions. The
              Customer will place his orders by email or in any other way. The
              version of the General Terms and Conditions enforceable against
              the Client will then be the latest version communicated in writing
              by the Company, in particular as an annex to any document provided
              by the Company to the Client, such as intervention orders, or by
              sending an email including the hypertext link allowing access to
              the General Terms and Conditions in force. The General Conditions
              of Sale may alternatively be made enforceable against the Customer
              by their publication at the address of the Company's website once
              said site has been created.
            </p>

            <p>
              Modification of the General Terms and Conditions. The Company may
              modify the T&Cs at any time, it being specified that only the T&Cs
              in force on the day the Customer's order is registered by the
              Company will be applicable.
            </p>

            <p>
              No waiver. The fact that the Company does not avail itself at a
              given time of any of the clauses of the General Conditions of Sale
              cannot be interpreted as a waiver of the right to subsequently
              avail itself of any of the clauses of the General Conditions of
              Sale.
            </p>

            <h4>II. ORDERS</h4>

            <p>
              Acceptance of the General Terms and Conditions. Any order placed
              by the Customer implies acceptance of the General Terms and
              Conditions in force on the day of the order.
            </p>

            <p>
              Sales training. The sale of Products and/or Services is validly
              formed upon confirmation by the Company of the order placed by the
              Customer.
            </p>

            <p>
              Availability of Products. The Company will inform the Customer by
              any means in the event of unavailability of all or part of the
              Products ordered, the Customer accepting in advance that the order
              can then only relate to the Products available on the day of the
              order.
            </p>

            <p>
              Intuitive personae. The benefit of the order is personal to the
              Customer and cannot, therefore, be transferred without the prior
              written consent of the Company.
            </p>

            <p>
              Modification – Cancellation. No modification or cancellation of
              orders will be accepted without prior written agreement from the
              Company.
            </p>

            <h4>III. PRICES AND PAYMENT TERMS</h4>

            <p>
              Price. The Products and Services are provided at the prices in
              effect on the day the order is placed. These prices will be valid
              for the current calendar year on the day of the order.
            </p>

            <p>
              Prices are expressed in euros, net of all taxes, all customs
              duties, impositions, fees or contributions, as well as all costs
              or charges, such as in particular, transport, customs clearance or
              insurance costs, which remain to be paid. the responsibility of
              the Customer.
            </p>

            <p>
              Transport costs will be invoiced to the Customer according to the
              rate or scale communicated, where applicable, by the Company, or
              on the basis of a prior quote.
            </p>

            <p>
              The prices of the Products and Services will be revised by the
              Company for each new calendar year
            </p>

            <p>
              The price of the Product and/or Service ordered by the Customer
              will be communicated to him by the Company by any means such as by
              sending the corresponding quote, by email or by SMS.
            </p>
            <p>
              Unless otherwise expressly agreed between the Parties, the price
              of the Product includes its installation and training on the use
              of the Product by the Company.
            </p>

            <p>
              Billing and payment terms. The price of the Products and/or
              Services ordered will be paid according to the following terms:
            </p>
            <p>
              - deposit of 60% (sixty percent) of the price of the Products
              and/or Services ordered, on the day of the order;
            </p>

            <p>
              - balance, i.e. 40% (forty percent) of the price of the Products
              and/or Services ordered on the day of delivery.
            </p>
            <p>
              Unless otherwise expressly agreed between the parties, the Client
              undertakes to pay the Company's invoices upon receipt.
            </p>

            <p>
              Invoices will be paid by bank transfer, check or SEPA direct
              debit. In the event of payment by SEPA direct debit, the Customer
              undertakes to provide a bank identity statement and a direct debit
              authorization to the Company for all sums due for orders for
              Products and/or Services.
            </p>
            <p>
              Delivery of the Products and/or Services ordered can only take
              place once the Company has received the sums due under the order,
              which the Customer expressly accepts.
            </p>

            <p>
              Early payment of invoices does not entitle you to any discount.
            </p>

            <p>
              Late payment. A penalty for late payment will be automatically due
              to the Company by the Customer from the first day following the
              due date of the corresponding invoice in the event that the
              invoice remains unpaid after this due date. This penalty for late
              payment will be calculated on the basis of the interest rate
              applied by the European Central Bank to its most recent
              refinancing operation increased by 10 percentage points. In
              addition, the Company may invoice the Customer for a fixed
              compensation of forty euros (€40) per unpaid invoice after its due
              date.
            </p>
            <p>
              In the absence of payment of a single invoice when due, after
              formal notice remains unsuccessful within fifteen (15) days, all
              other invoices issued to the Customer will be immediately due. The
              Company will also have the option of suspending delivery of orders
              for Products and/or its Services in progress until full payment of
              the amounts due.
            </p>

            <h4>VI. DELIVERY</h4>

            <p>
              Delivery terms. The Products are delivered by the Company or a
              carrier selected by the Company to the address of the Customer's
              headquarters, unless specific stipulations expressly agreed
              between the parties, it being remembered that transport costs are
              the responsibility of the Customer so that this the latter must
              reimburse them to the Company in the event that the latter makes
              an advance.
            </p>
            <p>
              Delivery delay. Delivery times which could, where applicable, be
              indicated by the Company are not mandatory and are given for
              purely indicative purposes. Consequently, non-compliance with the
              delivery times indicated cannot result in the cancellation of the
              sale, the Customer cannot claim penalties, late payment interest
              or damages in this regard, as long as delivery takes place within
              a reasonable time.{" "}
            </p>
            <p>
              Force majeure. The Company cannot in any way be held responsible
              in the event of non-compliance with its obligations and in
              particular the delivery time, due to the occurrence of an event of
              force majeure within the meaning of article 1218 of the Civil
              Code.
            </p>
            <p>
              A force majeure event is defined in the General Terms and
              Conditions as, this list not being exhaustive: production
              incidents of the Products, incidents of transport or supply of
              spare parts, stock shortages of the Products which are not the
              fault of the Company , supplier delays, natural disaster, act of
              the Prince, war, state of emergency, riot, insurrection, fire,
              explosion, flood, pandemic, epidemic or health crisis, whether
              local or national, caused in particular by a coronavirus,
              accident, strike, factory closure or any other social conflict.
            </p>
            <p>
              Verification. In the event that the Customer wishes only delivery
              of the Product ordered, without its installation by the Company,
              the Customer must check the condition and quality of the Product
              ordered upon delivery as well as its conformity with the order
              placed. In the event of non-conformity of the Product, the
              Customer is required to make all necessary findings upon receipt
              of the Product, to indicate its reservations to the Company on the
              delivery note dated and signed by the Customer and to inform the
              Company thereof. in writing within twenty-four hours following
              receipt of the Product ordered, it being specified that no
              complaint can be accepted after this period.
            </p>
            <p>
              Action against the carrier. The Customer acknowledges that the
              transport companies which would, where applicable, be responsible
              for the delivery of the Products, are solely responsible for any
              damage that may occur during transport and that it is up to him to
              assert his rights against the carriers, the Company does not
              provide any guarantee in this regard.
            </p>

            <h4>V. SUBCONTRACTING</h4>
            <p>
              The Company may subcontract all or part of the Services ordered by
              the Client, which the Client expressly accepts.
            </p>

            <h4>VI. BACK</h4>
            <p>
              No return of the Products will be possible in the absence of prior
              agreement from the Company or in the event of unreserved receipt
              of the Products by the Customer.
            </p>
            <p>
              Products returned by the Customer must be accompanied by a return
              slip and sent to the specific address indicated, where applicable,
              by the Company, for the return of the Products.
            </p>
            <p>
              Any return of Products accepted by the Company results in the
              establishment of a credit for the benefit of the Customer,
              corresponding to the value of the Products returned.
            </p>
            <p>
              The Customer will bear the costs and risks of returning the
              Products, unless the lack of conformity is attributable to the
              Company.
            </p>

            <h4>VII. RISKS – PROPERTY</h4>

            <p>
              Risks. The risks relating to the Products sold are borne by the
              Customer as soon as they are handed over to the Customer or, where
              applicable, to the carrier who would be responsible for their
              delivery to the Customer.
            </p>

            <p>
              Reservation of title. The Products remain the property of the
              Company until full payment of the price by the Customer. In the
              absence of full payment on the due date, the Customer undertakes
              to return, at its own expense, the Products concerned, upon first
              request from the Company, so that the latter regains the right to
              dispose of them.
            </p>

            <h4>VIII. MANUFACTURER'S WARRANTY</h4>

            <p>
              The Customer is informed that the Products are subject to a
              manufacturer's warranty, the duration of which may vary depending
              on the Product, from the day on which the Product concerned leaves
              the manufacturer's storage warehouse to be delivered to the
              Customer having passed order from the Company.
            </p>

            <p>
              To be able to invoke the benefit of this guarantee, the Customer
              must notify the Company in writing during the duration of the
              guarantee relating to the Product, of his wish to enforce the
              manufacturer's guarantee and allow the Company to make any
              findings on the Product.
            </p>

            <p>
              Application of this guarantee will give rise to free replacement
              of defective parts of the Product, excluding wearing parts
              (gaskets for example).
            </p>

            <p>
              This guarantee will be excluded in the event of damage or defects
              resulting from handling not in accordance with the manufacturer's
              recommendations or from non-compliance with the manufacturer's
              instructions for use and maintenance, by the Customer, as well as
              in the event where the Customer would like delivery of the Product
              ordered alone, without its installation by the Company.
            </p>

            <h4>IX. RESPONSIBILITY</h4>

            <p>
              Obligation of means. The Company is subject to an obligation of
              means under the General Terms and Conditions. The Company's
              liability is limited to the sole provision of the Products and
              Services to the Customer so that the Company will not be held
              liable under the eviction guarantee, unless it is its personal
              act, and will not be further responsible for hidden defects in the
              Products.
            </p>

            <p>
              Compliance with laws and regulations. The Company declares and
              guarantees that the Products sold and delivered to the Customer
              comply with the laws and regulations in force in France.
            </p>

            <p>
              Exclusion of consequential damages. The Customer may under no
              circumstances claim damages from the Company on any grounds
              whatsoever for loss of profit or for commercial loss or other
              indirect damage such as operating loss or delay compensation.
            </p>

            <p>
              Limitation of Liability. In any event, no compensation possibly
              owed by the Company may be greater than the sales price received
              by the Company for the Products and/or Services in question.
            </p>

            <h4>X. INTELLECTUAL PROPERTY</h4>

            <p>
              The Customer acknowledges that the simple fact of purchasing
              Products from the Company does not confer any rights on the brands
              and other intellectual property rights belonging to the Customer
              or third parties. The Customer is therefore prohibited in
              particular from carrying out any act that could be qualified as
              counterfeiting, without time limit and for the entire world.
            </p>

            <h4>XI. CONFIDENTIALITY</h4>

            <p>
              The Customer undertakes to maintain the strictest confidentiality
              regarding the information received from the Company in the context
              of orders for Products and Services, even if said information is
              not by nature confidential.{" "}
            </p>

            <h4>XII. COMPLAINTS</h4>

            <p>
              The Customer must send any complaints in writing to the following
              address: SAV MACHINE A CAFE, 60, rue François 1er, 75008 Paris, or
              by email to the following address:{" "}
              <a href="savmachineacafe@free.fr">savmachineacafe@free.fr</a>,
              stating the number and the date of the order.
            </p>

            <h4>XIII. APPLICABLE LAW – JURISDICTION</h4>

            <p>
              Applicable right. The General Terms and Conditions are subject to
              French law.
            </p>

            <p>
              Skill. Any dispute likely to arise from the General Conditions of
              Sale and concerning in particular their validity, interpretation,
              execution or non-execution, or successive sales of Products or
              provision of Services between the Company and the Customer in
              application of the General Conditions of Sale, will fall under the
              exclusive jurisdiction of the Court of trade of Paris.
            </p>
          </div>
         : 
          <div>
          <h4>I.	CHAMP D’APPLICATION</h4>

          <p><b>Champ d’application</b>. Les présentes conditions générales de vente (ci-après les « CGV ») s'appliquent aux ventes de machines d’équipements mécaniques, tels que des machines à café, des machines à granité, des machines à glace italienne ou encore des presse-agrumes (ci-après les « Produits ») vendus par la société SAV MACHINE A CAFE, société par actions simplifiée au capital de 500 euros, immatriculée au Registre du commerce et des sociétés de Paris sous le numéro 919 104 018, dont le siège social est sis 60, rue François 1er, 75008 Paris (ci-après la « Société ») ainsi qu’aux prestations de services d’installation, d’entretien et de réparation des Produits (ci-après les « Services  ») rendus par la Société, à tout client professionnel (ci-après le « Client »).</p>

          <p><b>Intégralité des CGV</b>. Les CGV prévalent sur toute autre proposition, accord ou communication antérieurs relatifs à l’opération de vente considérée.</p>

          <p><b>Divisibilité</b>. Dans le cas où l'une quelconque des dispositions des présentes serait réputée ou déclarée nulle ou non écrite, les autres dispositions resteront intégralement en vigueur.</p>

          <h4>II.	OPPOSABILITE</h4>

          <p><b>Version opposable des CGV</b>. Le Client passera ses commandes par courriel ou de toute autre manière. La version des CGV opposable au Client sera alors la dernière version communiquée par écrit par la Société, notamment en annexe à tout document fourni par la Société au Client, tels que les bons d’intervention, ou par l’envoi d’un courriel comportant le lien hypertexte lui permettant d’accéder aux CGV en vigueur. Les CGV pourront alternativement être rendues opposables au Client par leur publication à l’adresse du site Internet de la Société dès lors que ledit site aurait été créé.</p>

          <p><b>Modification des CGV</b>. La Société pourra modifier à tout moment les CGV, étant précisé que seules les CGV en vigueur au jour de l’enregistrement de la commande du Client par la Société seront applicables.</p>

          <p><b>Absence de renonciation</b>. Le fait que la Société ne se prévale pas à un moment donné de l'une quelconque des clauses des CGV ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de l'une quelconque desdites CGV.</p>

          <h4>III.	COMMANDES</h4>

          <p><b>Acceptation des CGV</b>. Toute commande réalisée par le Client emporte acceptation des CGV en vigueur au jour de la commande.</p>

          <p><b>Formation de la vente</b>. La vente de Produits et/ou de Services est valablement formée dès confirmation par la Société de la commande passée par le Client.</p>

          <p><b>Disponibilité des Produits</b>. La Société informera le Client par tout moyen en cas d’indisponibilité de tout ou partie des Produits commandés, le Client acceptant par avance que la commande ne puisse alors porter que sur les Produits disponibles au jour de la commande.</p>

          <p><b>Intuitu personae</b>. Le bénéfice de la commande est personnel au Client et ne peut, en conséquence, être cédé sans l'accord préalable écrit de la Société.</p>

          <p><b>Modification – Annulation</b>. Aucune modification ni aucune annulation de commande ne seront acceptées, sauf accord préalable écrit de la Société.</p>

          <h4>IV.	PRIX ET CONDITIONS DE PAIEMENT</h4>

          <p><b>Prix</b>. Les Produits et Services sont fournis aux prix en vigueur au jour de la passation de la commande. Ces prix seront valables pour l’année civile en cours au jour de la commande.</p>

          <p>Les prix sont exprimés en euros, nets de toutes taxes, de tous droits de douanes, impositions, redevances ou contributions, ainsi que de tous frais ou charges, tels que notamment, frais de transport, de dédouanement ou d’assurance, qui restent à la charge du Client.</p>

          <p>Les frais de transport seront facturés au Client selon le tarif ou le barème communiqué, le cas échéant, par la Société, ou sur la base d’un devis préalable.</p>

          <p>Les prix des Produits et Services seront révisables par la Société pour chaque nouvelle année civile</p>

          <p>Le prix du Produit et/ou Service commandé par le Client lui sera communiqué par la Société par tout moyen tel que par l’envoi du devis correspondant, par courriel ou encore par SMS.</p>

          <p>Sauf stipulations contraires expressément convenues entre les Parties, le prix du Produit inclut son installation et une formation sur l’utilisation du Produit par la Société.</p>

          <p><b>Facturation et modalités de paiement</b>. Le prix des Produits et/ou Services commandés sera réglé selon les modalités suivantes :</p>

          <p>-	acompte de 60 % (soixante pour cent) du prix des Produits et/ou Services commandés, au jour de la commande ;</p>.

          <p>-	solde, soit 40 % (quarante pour cent) du prix des Produits et/ou Services commandés au jour de la livraison.</p>

          <p>Sauf stipulations contraires expressément convenues entre les parties, le Client s’engage à payer les factures de la Société à réception.</p>

          <p>Les factures seront payées par virement bancaire, chèque ou prélèvement SEPA. En cas de paiement par prélèvement SEPA, le Client s’engage à remettre un relevé d’identité bancaire et une autorisation de prélèvement à la Société pour l’ensemble des sommes dues au titre des commandes de Produits et/ou Services. </p>

          <p>La livraison des Produits et/ou Services commandés ne pourra intervenir qu’à compter de l’encaissement par la Société des sommes dues au titre de la commande, ce que le Client accepte expressément.</p>

          <p>Le paiement anticipé des factures n’ouvre droit à aucun escompte.</p>

          <p><b>Retard de paiement</b>. Une pénalité pour retard de paiement sera due de plein droit à la Société par le Client à compter du premier jour suivant la date d’échéance de la facture correspondante dans le cas où la facture resterait impayée après cette date d’échéance. Cette pénalité pour retard de paiement sera calculée sur la base du taux d’intérêt appliqué par la Banque Centrale Européenne à son opération de refinancement la plus récente majorée de 10 points de pourcentage. De plus, la Société pourra facturer au Client une indemnité forfaitaire de quarante euros (40 €) par facture impayée après sa date d’échéance.</p>

          <p>En l’absence de paiement d’une seule facture à son échéance, après une mise en demeure restée infructueuse dans le délai de quinze (15) jours, l’ensemble des autres factures émises au Client sera immédiatement exigible. La Société aura par ailleurs la possibilité de suspendre la livraison des commandes de Produits et/ou de ses Services en cours jusqu’au complet paiement des sommes dues.</p>

          <h4>V.	LIVRAISON</h4>

          <p><b>Modalités de livraison</b>. Les Produits sont livrés par la Société ou un transporteur sélectionné par la Société à l’adresse du siège du Client, sauf stipulations particulières expressément convenues entre les parties, étant rappelé que les frais de transport sont à la charge du Client de telle sorte que ce dernier devra les rembourser à la Société dans le cas où cette dernière en ferait l’avance.</p>

          <p><b>Délais de livraison</b>. Les délais de livraison qui pourraient le cas échéant être indiqués par la Société ne sont pas de rigueur et sont donnés à titre purement indicatif. En conséquence, le non-respect des délais de livraison indiqués ne pourra entrainer la résolution de la vente, le Client ne pouvant réclamer des pénalités, intérêts de retard ou dommages et intérêts à ce titre, dès lors que la livraison intervient dans un délai raisonnable.</p>

          <p><b>Force majeure</b>. La Société ne saurait en aucune manière être tenue responsable en cas de non-respect de ses obligations et notamment du délai de livraison, du fait de la survenance d’un événement de force majeure au sens de l’article 1218 du Code civil. </p>

          <p>Un évènement de force majeure est défini aux CGV comme, cette liste n’étant pas limitative : incidents de production des Produits, incidents de transport ou d’approvisionnement en pièces détachées, ruptures de stock des Produits qui ne sont pas le fait de la Société, retard de fournisseurs, désastre naturel, fait du Prince, guerre, état d’urgence, émeute, insurrection, incendie, explosion, inondation, pandémie, épidémie ou crise sanitaire, qu’elle soit locale ou nationale, causée notamment par un coronavirus, accident, grève, fermeture d’usine ou tout autre conflit social.  </p>

          <p><b>Vérification</b>. Dans l’hypothèse où le Client souhaiterait la seule livraison du Produit commandé, sans son installation par la Société, le Client devra vérifier l’état et la qualité du Produit commandé à sa livraison ainsi que sa conformité à la commande passée. En cas de non-conformité du Produit, le Client est tenu de faire toutes constatations nécessaires à la réception du Produit, d’indiquer ses réserves à la Société sur le bon de livraison daté et signé par le Client et d’en informer la Société par tout écrit dans les vingt-quatre heures suivant la réception du Produit commandé, étant précisé qu’aucune réclamation ne pourra être acceptée passé ce délai.</p>

          <p><b>Recours contre le transporteur</b>. Le Client reconnaît que les entreprises de transport qui seraient, le cas échéant, chargées de la livraison des Produits, sont seules responsables des dommages pouvant intervenir pendant le transport et qu’il lui appartient de faire valoir lui-même ses droits contre les transporteurs, la Société ne fournissant aucune garantie à ce titre.</p>

          <h4>VI.	SOUS-TRAITANCE</h4>

          <p>La Société pourra sous-traiter tout ou partie des Services commandés par le Client, ce que le Client accepte expressément.</p>

          <h4>VII.	RETOUR</h4>

          <p>Aucun retour des Produits ne sera possible en l’absence d’accord préalable de la Société ou en cas de réception sans réserve des Produits par le Client.</p>
          <p>Les Produits retournés par le Client devront être accompagnés d’un bon de retour et envoyés à l’adresse spécifique indiquée, le cas échéant, par la Société, pour le retour des Produits.</p>
          <p>Tout retour de Produits accepté par la Société entraine l’établissement d’un avoir au profit du Client, correspondant à la valeur des Produits retournés.</p>
          <p>Le Client supportera les frais et risques du retour des Produits, à moins que le défaut de conformité ne soit imputable à la Société.</p>

          <h4>VIII.	RISQUES – PROPRIÉTÉ</h4>
          <p><b>Risques</b>. Les risques afférents aux Produits vendus sont supportés par le Client dès leur remise au Client ou le cas échéant au transporteur qui serait chargé de leur livraison au Client.</p>

          <p><b>Réserve de propriété</b>. Les Produits demeurent la propriété de la Société jusqu'au complet paiement du prix par le Client. A défaut de complet paiement à l'échéance, le Client s’engage à restituer, à ses frais, les Produits concernés, sur première demande de la Société, afin que cette dernière recouvre le droit d'en disposer.</p>

          <h4>IX.	GARANTIE DU FABRICANT</h4>

          <p>Le Client est informé que les Produits font l’objet d’une garantie du fabricant, dont la durée peut varier selon le Produit, à compter du jour où le Produit concerné quitte l’entrepôt de stockage du fabricant pour être livré au Client ayant passé commande auprès de la Société. </p>
          <p>Pour pouvoir invoquer le bénéfice de cette garantie, le Client devra notifier à la Société par écrit pendant la durée de la garantie se rapportant au Produit, son souhait de faire appliquer la garantie du fabricant et permettre à la Société de procéder à toutes constatations sur le Produit.</p>

          <p>L’application de cette garantie donnera lieu au remplacement gratuit des pièces défectueuses du Produit, à l’exclusion des pièces d’usure (joints par exemple).</p>

          <p>Cette garantie sera exclue en cas de dommages ou défauts résultant d’une manipulation non conforme aux préconisations du fabricant ou d’un non-respect des instructions d’utilisation et d’entretien du fabricant, par le Client, ainsi que dans l’hypothèse où le Client souhaiterait la livraison seule du Produit commandé, sans son installation par la Société.</p>

          <h4>X.	RESPONSABILITE</h4>

          <p><b>Obligation de moyens</b>. La Société est soumise au titre des CGV à une obligation de moyens. La responsabilité de la Société est limitée à la seule fourniture des Produits et Services au Client de sorte que la Société ne sera pas tenue au titre de la garantie d’éviction, si ce n’est de son fait personnel, et ne sera pas davantage responsable des vices cachés des Produits.</p>

          <p><b>Respect des lois et règlements</b>. La Société déclare et garantit que les Produits vendus et livrés au Client sont conformes aux lois et règlements en vigueur en France. </p>

          <p><b>Exclusion des dommages indirects</b>. Le Client ne pourra en aucun cas réclamer à la Société des dommages et intérêts à quelque titre que ce soit pour manque à gagner ou au titre d’un préjudice commercial ou autre préjudice indirect tel que perte d’exploitation ou indemnité de retard.</p>

          <p><b>Limitation de responsabilité</b>. En tout état de cause, aucune indemnisation éventuellement due par la Société ne pourra être supérieure au prix de vente encaissé par la Société au titre des Produits et/ou Services en cause.</p>

          <h4>XI.	PROPRIETE INTELLECTUELLE</h4>

          <p>Le Client reconnaît que le simple fait d’acheter des Produits auprès de la Société ne lui confère aucun droit sur les marques et autres droits de propriété intellectuelle appartenant au Client ou à des tiers. Le Client s’interdit donc notamment de réaliser tout acte pouvant être qualifié de contrefaçon, sans limitation de durée et pour le monde entier.</p>

          <h4>XII.	CONFIDENTIALITE</h4>

          <p>Le Client s’engage à conserver la plus stricte confidentialité sur les informations reçues de la Société dans le cadre des commandes de Produits et Services, quand bien même lesdites informations ne seraient pas par nature confidentielles.</p>

          <h4>XIII.	DONNEES PERSONNELLES</h4>

          <p>La Société s’engage à collecter et à traiter toute donnée personnelle du Client en conformité avec toute réglementation en vigueur applicable au traitement de ces données, notamment la Réglementation relative aux données personnelles.</p>

          <p>Pour les besoins des CGV, « Réglementation relative aux données personnelles » signifie toutes les lois et règlementations relatives à la protection des données applicables au traitement de données dans le cadre des CGU, y compris les lois nationales de protection des données en vigueur en France (telles que la loi n°78-17 du 6 janvier 1978 modifiée relative à l’Informatique, aux Fichiers et aux Libertés) et le Règlement UE 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (le Règlement général sur la protection des données).</p>

          <h4>XIV.	RECLAMATIONS</h4>
          <p>Le Client doit adresser ses éventuelles réclamations par écrit à l’adresse suivante : SAV MACHINE A CAFE, 60, rue François 1er, 75008 Paris, ou par courriel à l’adresse suivante </p>

          </div>
          }
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Term);

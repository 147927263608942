import React, { useState,useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { searchApi } from "../AllAPIS";
import { connect } from "react-redux";

import "./../Styles/banner.css";

function Banner({ getSelectedLanguage }) {
  const navigation = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState("");
  const [searchData, setSearchData] = useState();

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
    
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSearchData([])
        }
      }
      document?.addEventListener("mousedown", handleClickOutside);
      return () => {
        document?.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const handleSearch = () => {
    searchApi(getSelectedLanguage, data, (callData) => {
      setSearchData(callData);
    });
  };

  return (
    <div className="banner">
      <p className="text-white">SAV MACHINES A CAFE</p>
      <h2 className="text-white fw-bolder m-0">
       {t("Your search for all commercial coffee Provide")}
      </h2>
      <h2 className="text-white fw-bolder">{t("machines & Equipment Ends Here")}</h2>
      <div className="banner-phone-input pt-4">
        <BiSearch onClick={handleSearch} />
        <input
          value={data}
          onChange={(e) => {
            setData(e.target.value);
            setSearchData([]);
            searchApi(getSelectedLanguage, e.target.value, (callData) => {
              setSearchData([...callData]);
            });
          }}
          type="text"
          placeholder={t("Search For Something")}
        />
      </div>
      {!searchData || searchData?.length === 0 ? null : (
        <div ref={wrapperRef} className="search_dropdown">
          <ul>
            {searchData && searchData?.map((item, i) => {
              return <li onClick={()=>{navigation(`/service/${item?.slug}/${item.id}`)}} key={i}>{item?.name}</li>;
            })}
          </ul>
        </div>
      )}

      <div className="banner-buttons py-4">
        <button
          className="btn-colored mx-2"
          onClick={() => navigation("/coffee-machine-a-cafe/all-services")}
        >
          {t("Book Service")}
        </button>
        <span
          className="banner-contact-us-button mx-2"
          onClick={() => navigation("/coffee-machine-a-cafe/contact-us")}
        >
          {t("Contact Us")}
        </span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

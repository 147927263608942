import React from 'react'
import './../Styles/technician.css'

function TechnicianHome() {
  return (
    <>
  
    <div className="coming_soon">
      <img src="/img/logo.png" alt="logo-img" />
        <h2>We are <span>Coming Soon!</span></h2>
        <p>We are almost there! If you want to get notified when the website goes live subscribe to our mailing list!</p>
         <input type='email' placeholder='Enter Email'  />
         <button>Notify Me!</button>
    </div>
    <div className="main-invest d-none">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6 d-flex justify-content-center align-items-center ">
            <div className="investor-top-first-section">
              <h2>
                Grow your <span>money!</span>
              </h2>
              <p>Fixed income yields with attractive risk adjusted returns</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="investor-top-first-img">
              <img
                src="https://img.freepik.com/free-vector/hand-drawn-stock-market-concept_52683-76566.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5 pt-5">
        <div className="invest-middle-heading">
          <h3>Invest on your terms</h3>
          <h5>Define your investment goal and risk profile</h5>
        </div>
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="invest-tabs">
              <span>
                {" "}
                <img
                  src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/62f254597568b4bb077ccb5b_fluent_target-arrow-24-filled.png"
                  alt=""
                />{" "}
                Set Target Return
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="invest-tabs">
              <span>
                {" "}
                <img
                  src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/62fbc2d6c67ba2d4d69e5fd5_bi_bookmark-star.svg"
                  alt=""
                />{" "}
                Filter Companies
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="invest-tabs">
              <span>
                {" "}
                <img
                  src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/62e92bbf13efa624a1c5e7c5_emojione_money-bag.svg"
                  alt="img"
                />{" "}
                Invest On Your Terms
              </span>
            </div>
          </div>
        </div>

        <div className="row mt-5 shadow bg-white py-5 white-box-invest">
          <div className="col-md-6">
            <div className="invest-second-last-first-section">
              <img
                src="https://uploads-ssl.webflow.com/62c3cad34a5b3235b0d518de/63037d166e4dd6fa6822acaa_Filter%20companies-p-500.webp"
                alt="img"
              />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="invest-second-last-second-section">
              <h2>2. Filter Companies</h2>
              <h5>
                Our Simple to use filters will help you select your target
                company profile.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center ">
            <div className="invest-login-left">
              <h1>Are you ready to grow your money?</h1>
              <button>SIGNUP {"->"}</button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="container">
              <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
              <div className="invest-signup-form">
                <div className="form">
                  <h1>Signup</h1>
                  <div className="register-input-single mt-2">
                    <label
                      htmlFor="fname"
                    >
                      Name*
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Name"
                    />
                  </div>

                  <div className="register-input-single mt-2">
                    <label
                      htmlFor="fname"
                    >
                      Email*
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      placeholder="Enter Email"
                    />
                  </div>

                  <div className="register-input-single mt-2">
                    <label
                      htmlFor="password"
                    >
                      Password*
                    </label>
                    <input
                      id="password"
                      name="password"
                      placeholder="********"
                    />
                   
                  
                  </div>


                  <div className="register-buttons">
                    <div
                      className="google-register-btn"
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-9 google-button">
                            <p>Signup with</p>
                          </div>
                          <div className="col-md-3 google-button">
                            <img src="/Icons/google-icon.png" alt="Image" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="register-btn"
                    >
                      Sign up
                    </button>
                  </div>

                </div>
              </div>
              </div>
              {/* <div className="col-md-2"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default TechnicianHome

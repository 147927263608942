import React from 'react'
import { useNavigate } from 'react-router-dom'
import './../Styles/checkoutheader.css'

function CheckoutHeader() {
  const navigation =useNavigate()
  return (
    <div className='checkoutheader' >
    <div className="container">
        <div className="row">
            <div className="col-2"></div>
            <div className="col-3">
                 <div onClick={()=>navigation('/')} className=" d-flex align-items-center justify-content-between  header_checkout">
                     <img src="/img/logo.png" alt="logo" />
                     <h4>Checkout</h4>
                 </div>
            </div>
            <div className="col-7"></div>
        </div>
    </div>
    </div>
  )
}

export default CheckoutHeader

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import { setStoreUserToken } from "../Redux";
import { useNavigate } from "react-router-dom";
import { ErrorToaster } from "../Toasters";
import { loginApi } from "../AllAPIS";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import ForgotModal from "../Modals/ForgotModal";
import "./../Styles/login.css";
import { useTranslation } from "react-i18next";

let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
function Login({ setStoreUserTokenRedux, getStoreUserToken }) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [validated, setValidated] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailrror] = useState(false);
  const [emailMsg, setEmailMsg] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" && password === "") {
      ErrorToaster("Please Fill The All Fields");
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
    } else if (email === "") {
      setEmailrror(true);
      ErrorToaster("Email Is Required");
      setEmailMsg("Email Is Required");
    } else if (emailreg.test(email) === false) {
      ErrorToaster("Email Format is not correct it Contain @ & .com");
      setEmailMsg("Email Format is not correct it Contain @ & .com");
      setEmailrror(true);
    } else {
      let loginData = {
        email: email,
        password: password,
        device_token: "BNQASDFWP30KCYBER678IPFO",
        token:'bfc04fe1-a576-4210-94d7-d70d7a6a124b'
      };
      loginApi(loginData, navigation, setStoreUserTokenRedux);
    }
  };

  useEffect(() => {
    if (getStoreUserToken) {
      ErrorToaster("You Have Already Login");
      navigation("/");
    }
  }, []);

  return (
    <>
      <ForgotModal
        setShowForgotModal={setShowForgotModal}
        showForgotModal={showForgotModal}
      />
      <div className="container-fluid login-main">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 login-left m-0 p-0">
            <img src="/img/login-img.png" alt="" />
          </div>
          <div className="col-md-6 login-right ">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <h3 className="fw-bold">Account Login</h3>
                <p className="text-muted pixel-16 mb-4">
                 {t("login1")}
                </p>
                <Form noValidate validated={validated} onSubmit={handleLogin}>
                  <Form.Group
                    className="mb-3"
                    controlId="validationCustomEmail"
                  >
                    <Form.Label> {t("login2")}</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder={t("login3")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError ? (
                      <span className="signup-error">{emailMsg}</span>
                    ) : (
                      <Form.Control.Feedback type="invalid">
                        {t("login4")}
                      </Form.Control.Feedback>
                    )}
                    <Form.Text className="text-muted pixel-12">
                      {t("login5")}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="validationCustomPassword"
                  >
                    <Form.Label>{t("Password")}</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        required
                        type={showPassword ? "text" : "password"}
                        placeholder={t("Password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {showPassword ? (
                        <AiOutlineEyeInvisible
                          onClick={() => setShowPassword(!showPassword)}
                          className="login-eye-icond"
                        />
                      ) : (
                        <AiOutlineEye
                          onClick={() => setShowPassword(!showPassword)}
                          className="login-eye-icond"
                        />
                      )}
                    </div>

                    <Form.Control.Feedback type="invalid">
                      {t("login6")}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <p
                    className="forgot_password"
                    onClick={() => setShowForgotModal(true)}
                  >
                    {t("login7")}
                  </p>
                  <button className="btn-colored mt-3" type="submit">
                    {t("Login")}
                  </button>
                </Form>

                <div className="text-mute pixel-14 mt-4 already-account">
                  {t("login8")}
                  <span
                    onClick={() => {
                      navigation("/signup");
                    }}
                  >
                    {t("login9")}
                  </span>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreUserTokenRedux: (parameter) => {
      dispatch(setStoreUserToken(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

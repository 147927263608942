import React, { useState, useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Navbar from "../Sections/Navbar";
import { connect } from "react-redux";
import { FetchAllOrderApi } from "../AllAPIS";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import { CancelOrderApi } from "../AllAPIS";
import "./../Styles/bookingpage.css";
import { useTranslation } from "react-i18next";

function BookingPage({ getSelectedLanguage }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [data, setData] = useState([]);
  const [cancelIs, setCancelIs] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [updatePage, setupdatePage] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    let formdata = {
      lang: getSelectedLanguage,
      page: page,
    };
    FetchAllOrderApi(tokenSav, formdata, setLoader, (data) => {
      setData(data.data.placed_order_list);
      setTotalPage(Math.ceil(data?.total_count / 10));
    });
  }, [getSelectedLanguage, updatePage]);

  const HandleChangePage = (number) => {
    setPage(number);
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    let formdata = {
      lang: getSelectedLanguage,
      page: number,
    };
    FetchAllOrderApi(tokenSav, formdata, (callbackData) => {
      setData([...data, ...callbackData.data.placed_order_list]);
      setTotalPage(Math.ceil(callbackData?.total_count / 10));
    });
  };

  let items = [];
  for (let number = 1; number <= totalPage; number++) {
    items.push(
      <Pagination.Item
        onClick={() => HandleChangePage(number)}
        key={number}
        active={number === page}
      >
        {number}
      </Pagination.Item>
    );
  }

  const handleCancel = (id) => {
    let formdata = {
      order_number: id,
      cancellation_reason: cancelReason,
    };
    var tokenSav = localStorage.getItem("SAVMACHINETOKEN");
    CancelOrderApi(tokenSav, formdata, setupdatePage);
  };

  return (
    <>
      <Navbar />
      {loader ? (
        <div className="loader-main">
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          >
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div className="container booking">
          <div className="d-flex align-items-center top-heading font-bold-7">
            <AiOutlineArrowLeft
              onClick={() => navigate(-1)}
              className="font-bold-7"
            />
            <h5 className="p-0 mx-3 font-bold-7">{t("my_booking_heading")}</h5>
          </div>
          {data?.map((item, i) => {
            return (
              <div key={i} className="container">
                <div className="row header mt-3">
                  <div className="d-flex justify-content-between align-items-center nav-bar">
                    <p>
                      {t("BookingID")}:<span> {item?.order_number}</span>
                    </p>
                    <p>
                      {t("Status")} :
                      <span style={{color : item?.order_status==='Service completed'?'green':"red"}} className="booking_status">
                        {" "}
                        {item?.order_status}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-3 booking-left">
                    <img src={item?.service_package_image} alt="image" />
                  </div>
                  <div className="col-md-6 booking-right py-4">
                    <h3>{item?.service_package_name}</h3>
                    {/* <button className="button">30 DAYS WARRANTY</button> */}
                    <div className="d-flex align-items-center mt-2">
                      <i
                        className="fa fa-map-marker icon"
                        aria-hidden="true"
                      ></i>
                      <p className="pl-2 m-0 service">
                        {t("Address")} :<span>{item?.billing_address}</span>
                      </p>
                    </div>
                    <p className="service">
                      {t("Service")} :<span> {item?.service_type_name}</span>
                    </p>
                    <p className="service">
                      {t("DateTime")} :
                      <span>
                        {" "}
                        {item?.time_slot} on {item?.date_of_service}
                      </span>
                    </p>

                    {item?.order_status === "Service completed" ? (
                      <h6 className="cancel_order_text">Completed</h6>
                    ) : item?.order_status === "Cancelled" ? (
                      <h6 className="cancel_order_text">{t("Cancelled")}</h6>
                    ) : cancelIs === item?.order_number ? (
                      <>
                        <textarea
                          name="cancel"
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control mt-2"
                          placeholder="Reason For Cancel"
                          value={cancelReason}
                          onChange={(e) => {
                            setCancelReason(e.target.value);
                          }}
                        ></textarea>
                        <button
                          onClick={() => {
                            handleCancel(item?.order_number);
                          }}
                          className="footer-btn "
                        >
                          {t("Cancel")}
                        </button>
                      </>
                    ) : (
                      <button
                        onClick={() => {
                          setCancelIs(item?.order_number);
                        }}
                        className="footer-btn "
                      >
                        {t("Cancel Booking")}
                      </button>
                    )}
                  </div>

                  <div className="col-12 col-sm-3 booking-right px-2 px-sm-0 py-sm-4">
                    <h3>{t("Technician Details")} :-</h3>
                    <div className="mt-2">
                      <p className="pl-2 m-0 service">
                        {t("Email")} :<span> {item?.technician_email} </span>
                      </p>
                      <p className="pl-2 m-0 service">
                        {t("Name")} :<span> {item?.technician_name} </span>
                      </p>
                      <p className="pl-2 m-0 service">
                        {t("Mobile Number")} :
                        <span> {item?.technician_phone} </span>
                      </p>
                      <p className="pl-2 m-0 service">
                        {item?.cancellation_reason === null ? null : (
                          <span>
                            <span style={{color:"#000"}} >{t("Cancel Reason")}</span> :
                            <span> {item?.cancellation_reason} </span>
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="mt-3">
            <Pagination linkStyle={{ color: "red" }}>{items}</Pagination>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPage);

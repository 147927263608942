
import React, {useEffect} from 'react';
import './App.css';
import { useLayoutEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { setStoreUserToken, setUserCordinates , setSelectedLanguages} from './Redux';
import { connect } from 'react-redux';
import Routes from './Routes';
import i18next from "i18next";



var token = localStorage.getItem('SAVMACHINETOKEN')
var language = localStorage.getItem('SAVMACHINELANG')

function App({setStoreUserTokenRedux, setSelectedLanguagesRedux, setUserCordinatesRedux}) {

  useEffect(()=>{
    setStoreUserTokenRedux(token)

  },[token])

  useLayoutEffect(()=>{
    setSelectedLanguagesRedux(language ? language: 'en')
    i18next.changeLanguage(language ? language: 'en')
  },[])


  return (
    <div>
        <Toaster />
        <Routes/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getSelectedLanguage: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStoreUserTokenRedux: (parameter) => {
      dispatch(setStoreUserToken(parameter));
    },
    setUserCordinatesRedux: (parameter) => {
      dispatch(setUserCordinates(parameter));
    },
    setSelectedLanguagesRedux: (parameter) => {
      dispatch(setSelectedLanguages(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);



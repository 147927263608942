import { ADDRESS} from "../type"

//initializing state 
const initialState = {
    address
    : 
   null,
    city
    : 
   null,
    country
    : 
    null,
    houseNo
    : 
    null,
    nearBy
    : 
    null,
    zipCode
    : 
    null,
}

const setAddressReducer= (state = initialState, action) => {
    switch (action.type) {
        case ADDRESS: return {
            ...state,
            address: action.payload.address,
            city: action.payload.city,
            country: action.payload.country,
            houseNo: action.payload.houseNo,
            zipCode: action.payload.zipCode,
            nearBy:action.payload.nearBy
        }
        default: return state
    }
}

export default setAddressReducer;
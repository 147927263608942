import { ALL_SERVICES} from "../type"

//initializing state 
const initialState = {
    services: [],
}

const setAllServicesReducers= (state = initialState, action) => {
    switch (action.type) {
        case ALL_SERVICES: return {
            ...state,
            services: action.payload,
        }
        default: return state
    }
}

export default setAllServicesReducers;
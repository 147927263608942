import React, { useState, useRef , useLayoutEffect} from "react";
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai'
import { FetchFaqApi } from "../AllAPIS";
import { connect } from "react-redux";
import './../Styles/faq.css'

const faqs = [
    {
        id: 1,
        header: "Why choose SAV Machines?",
        text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
    },
    {
        id: 2,
        header: "What are the services offered by SAV Machines?",
        text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `
    },
    {
        id: 3,
        header: "SAV Machines India Pvt. Ltd. is a complete coffee solutions company and",
        text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`
    },
    {
        id: 4,
        header: "Does SAV Machine have its own products?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    },
    {
        id: 5,
        header: "Is professional barista training helpful?",
        text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`
    }
]

const AccordionItem = (props) => {
    const contentEl = useRef();
   
    const { handleToggle, active, faq } = props;
    const { answer, id, question } = faq;



    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-2 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title">{question}</h5>
                    {
                        active === id
                        ?
                        <AiOutlineMinus />
                        :
                        <AiOutlinePlus/>
                    }
                 
                  
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0'>{answer}</p>
                </div>
            </div>
        </div>
    )
}

const FAQ = ({getSelectedLanguage}) => {
    
    const [active, setActive] = useState(null);
    const[faqData, setFaqData] = useState([])

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    
    useLayoutEffect(()=>{
        let formdata={
            "page": 1,
            "lang": getSelectedLanguage
          }
      FetchFaqApi(formdata, data=>{
        setFaqData(data)
      })
    },[getSelectedLanguage])
    
    return (
        <>
            <div className="container-fluid ">
                <div className="row justify-content-center">
                    <div className="col-md-12 ">
                        <div className="faq-card py-5">
                            <div className="headings-faq mb-5">
                            <h4 className="text-center" >Frequently Asked <span>Questions</span></h4>
                            <h6 className="text-center">It can help you get hired for your first job or advance your career. For coffee shop or restaurant owners, offering your staff ongoing training and quality professional .</h6>
                            </div>
                            <div className="faq-card-body container">
                                {faqData.map((faq, index) => {
                                     return (
                                            <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    return {
      getSelectedLanguage: state.language.language,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
 
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
  
  
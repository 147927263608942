import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactCodeInput  from 'react-code-input'
import NewPasswordModal from "./NewPasswordModal";
import { otpApi } from "../AllAPIS";
import "./../Styles/forgotmodal.css";


function ForgotCodeModal({ setShowForgotCodeModal, showForgotCodeModal }) {
  const handleClose = () => setShowForgotCodeModal(false);
  const [ codeValue, setCodeValue] = useState()
  const [showNewPasswordModal , setShowNewPasswordModal] = useState(false)
  const [resetToken, setResetToken] = useState()
  return (
    <>
    <NewPasswordModal resetToken={resetToken} showNewPasswordModal={showNewPasswordModal} setShowNewPasswordModal={setShowNewPasswordModal} />
      <Modal show={showForgotCodeModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title" >OTP Code Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
          Please enter your email and we will send on OPT code in next step to reset your password
          </h5>
          <div className="forgot_form my-4">
            <ReactCodeInput
              type='number'
              fields={4} 
              isValid={true}
              onChange={(e)=>setCodeValue(e)}
              value={codeValue}
               />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="forgot_button" onClick={()=>{
            // setShowNewPasswordModal(true)
            otpApi(codeValue , setShowNewPasswordModal, setShowForgotCodeModal, setResetToken)
          }}>
          Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ForgotCodeModal;

import { SELECTED_LANGUAGE} from "../type"

//initializing state 
const initialState = {
    language: 'en',
}

const setSelectedLanguageReducer= (state = initialState, action) => {
    switch (action.type) {
        case SELECTED_LANGUAGE: return {
            ...state,
            language: action.payload,
        }
        default: return state
    }
}

export default setSelectedLanguageReducer;
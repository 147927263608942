import React from "react";
import "./../Styles/workflow.css";
import { useTranslation } from "react-i18next";


function WorkFlow() {
  const { t } = useTranslation();

  return (
    <div className="container my-4 ">
      <div className="column">
        <div className="work-flow-heading">
          <h1 className="text-center">
            <span> {t("How It Works")}</span>
          </h1>
          <h4 className="text-center">
            {t("workflow_para")}
          </h4>
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12">
                <div className="work-flow-img">
                    <img className="img-fluid" src="/img/work-flow.png" alt="" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkFlow;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiFillUnlock } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./../Styles/forgotmodal.css";


function ShowAppModal({
  setShowAppModal,
  showAppModal,
}) {
  const navigation =useNavigate()
  const handleClose = () => setShowAppModal(false);

  return (
    <>
      <Modal show={showAppModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title">Download App</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="forgot_heading">
           For Technician Please Download Our App 
          </h5>
          <div className="d-flex google_link justify-content-around my-4">
           <div>
             <img src="/img/google_play.png" alt="img" />
           </div>
           <div>
             <img src="/img/apple_play.png" alt="img" />
           </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="forgot_button"
            onClick={()=>{
                setShowAppModal(false)
                navigation('/')
            }}
          >
           Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


  
  export default ShowAppModal;
  


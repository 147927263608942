import React from 'react'
import {AiOutlineMail} from 'react-icons/ai'
import './../Styles/newslatter.css'
import { addNewsLetterApi } from '../AllAPIS'
import { useState } from 'react'
import { useTranslation } from "react-i18next";



function NewsLatter() {
  const [email, setEmail] = useState('')
  const { t } = useTranslation();
  
  const handleSubmit =()=>{
    addNewsLetterApi(email, setEmail)
    
  }
  return (
    <div className="container my-4 py-3">
    <div className="column">
      <div className="newslatter-heading">
        <h1 className="text-center">
          {t("Newsletter")}
        </h1>
        <h4 className="text-center">
        {t("newsletter_para")}
        </h4>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center w-100 ">
              <div className="newslatter-input">
                <AiOutlineMail/>
                 <input required type="email" placeholder={t("Enter Your Email")} onChange={(e)=>setEmail(e.target.value)} value={email} />
                 <button onClick={handleSubmit} className='btn-colored' >{t("submit")}</button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default NewsLatter

import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { MdLocationOn } from "react-icons/md";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import { setUserCordinates, setAddress } from "../Redux";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "./../Styles/forgotmodal.css";
import { ErrorToaster } from "../Toasters";

const AnyReactComponent = ({ text }) => (
  <div>
    <MdLocationOn />
  </div>
);

function LocationModal({
  showLocationModalModal,
  setShowLocationModalModal,
  setUserCordinatesRedux,
  getUserCordinatorRedux,
  setAddressRedux
}) {
 const [myAddress, setMyAddress] = useState("");
  const handleClose = () => setShowLocationModalModal(false);

  const [houseNo, setHouseNo] = useState('')
  const [nearBy, setNearBy] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: getUserCordinatorRedux?.lat, lng: getUserCordinatorRedux?.lng },
      map,
      title: "Change Location",
      draggable: true,
    });

    maps.event.addListener(marker, "dragend", function (marker) {
      var latLng = marker.latLng;
      console.log(latLng,'latLnglatLnglatLng')
      setUserCordinatesRedux({ lat: latLng.lat(), lng: latLng.lng() });
      const reverseGeocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat()},${latLng.lng()}&key=AIzaSyA34wL1MtQPCFqF21Bz6pd8H-WqQAeFRIE`;

      fetch(reverseGeocodingUrl)
        .then((response) => response.json())
        .then((result) => {
          if (result) {
            console.log(result?.results[0]?.formatted_address);
            setMyAddress(result?.results[0]?.formatted_address)
          } else {
            console.log("No address found");
          }
        });
    });
  };

  useLayoutEffect(() => {
    const reverseGeocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${getUserCordinatorRedux?.lat},${getUserCordinatorRedux?.lng}&key=AIzaSyA34wL1MtQPCFqF21Bz6pd8H-WqQAeFRIE`;

    fetch(reverseGeocodingUrl)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          console.log(result);
          setMyAddress(result?.results[0]?.formatted_address)
        } else {
          console.log("No address found");
        }
      });
  }, [showLocationModalModal]);

  const handleSaveAddress =()=>{
  if(houseNo===''|| nearBy===''|| zipCode===''||city===''||country===''){
    ErrorToaster('Please Fill All the Field Properly')
  }else{
    setAddressRedux({address: myAddress , houseNo:houseNo, nearBy:nearBy, zipCode:zipCode, city:city,country:country}) 
    setShowLocationModalModal(false)
  }
   }

  return (
    <>
      <Modal size="lg" centered={true} show={showLocationModalModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="forgot_title">Your Location</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col xs={12} md={6}>
            <div className="forgot_form my-4">
            <div style={{ height: "300px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyA34wL1MtQPCFqF21Bz6pd8H-WqQAeFRIE",
                }}
                defaultZoom={15}
                defaultCenter={{lat: getUserCordinatorRedux?.lat, lng: getUserCordinatorRedux?.lng }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              >
                <AnyReactComponent
                  lat={getUserCordinatorRedux?.lat}
                  lng={getUserCordinatorRedux?.lng}
                  text="My Marker"
                />
              </GoogleMapReact>
            </div>
          </div>
            </Col>

            <Col xs={12} md={6}>
            <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>House/Flat Number*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter House No"
                    autoFocus
                    value={houseNo}
                    onChange={(e)=>setHouseNo(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Landmark</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Near By"
                    autoFocus
                    value={nearBy}
                    onChange={(e)=>setNearBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    autoFocus
                    value={city}
                    onChange={(e)=>setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Zip Code"
                    autoFocus
                    value={zipCode}
                    onChange={(e)=>{
                      if(e.target.value.length>8){

                      } else{
                        setZipCode(e.target.value)
                      }
                     
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    autoFocus
                    value={country}
                    onChange={(e)=>setCountry(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
              
              </Col>
            </Row>
            </Col>
          </Row>

         
        </Container>
      </Modal.Body>
        <Modal.Footer>
          <button onClick={handleSaveAddress} className="forgot_button">Save</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getStoreUserToken: state.storeUserToken.token,
    getSelectedLanguage: state.language.language,
    getUserCordinatorRedux: state.userCordinates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserCordinatesRedux: (parameter) => {
      dispatch(setUserCordinates(parameter));
    },
    setAddressRedux: (parameter) => {
      dispatch(setAddress(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);
